import { IdcardOutlined, LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { IBrokerCodeInfo, ICarrierInfo } from '@coverforce-platform/cf-common-api-model';
import { AgentPortalUserType, Carrier } from '@coverforce-platform/cf-common-types';

import { useConfigurationStore } from '../../components/configuration/store';
import {
  BROKER_CODE_ACTION_TYPES,
  BROKER_CODE_MESSAGES,
  CARRIER_BROKER_CODE_HELP_LINK,
  DISABLED_BROKER_CONFIG_LIST,
} from '../../constants/brokerConstants';
import { Actions, INPUT_TYPES, supportEmail } from '../../globalConstants';
import { getEmailRegex } from '../../helpers/Utils';
import { GA_ACTION, GA_CATEGORY, GA_LABEL } from '../../pages/v2/brokerConfig/constants';
import { getCarrierSpecificCategory } from '../../pages/v2/brokerConfig/utils';
import { ANCHOR_TARGET } from '../../ui-core/V2/anchor/constants';
import { IFormConfig } from '../../ui-core/V2/cfFormDrawer/interface';
import { getOnlyNumber } from '../getOnlyNumber';
import { pushEvent } from '../googleAnalytics';
import { CarrierCodeHelpLinkStyled, CarrierInfoTagStyled, DrawerInfo } from './styles';

const getBrokerConfigInfo = ({ selectedCarrier }: { selectedCarrier?: ICarrierInfo }) => {
  return (
    selectedCarrier &&
    `Please send your codes to ${supportEmail} so we can contact ${selectedCarrier.carrierShortName} to enable digital quoting.`
  );
};

export const getBrokerCodeDescription = ({ selectedCarrier }: { selectedCarrier?: ICarrierInfo }) => {
  const extraInfo = getBrokerConfigInfo({ selectedCarrier });
  const handleClick = () => {
    selectedCarrier &&
      pushEvent({
        category: getCarrierSpecificCategory(GA_CATEGORY.BROKER_CONFIG_EDIT_DRAWER, selectedCarrier.carrierId),
        action: GA_ACTION.WHERE_TO_FIND_BTN_CLICK,
        label: GA_LABEL.WHERE_TO_FIND,
      });
  };
  return (
    selectedCarrier && (
      <>
        {CARRIER_BROKER_CODE_HELP_LINK[selectedCarrier.carrierId] && (
          <CarrierCodeHelpLinkStyled
            id='carrier_code_link'
            href={CARRIER_BROKER_CODE_HELP_LINK[selectedCarrier.carrierId]}
            target={ANCHOR_TARGET.BLANK}
            onClick={handleClick}
          >
            Where to find your {selectedCarrier?.carrierShortName} Codes?
          </CarrierCodeHelpLinkStyled>
        )}
        {extraInfo && DISABLED_BROKER_CONFIG_LIST.includes(selectedCarrier.carrierId) && (
          <DrawerInfo>
            <CarrierInfoTagStyled color='yellow'>{getBrokerConfigInfo({ selectedCarrier })}</CarrierInfoTagStyled>
          </DrawerInfo>
        )}
      </>
    )
  );
};

export const getBrokerCodeFullName = (config: IBrokerCodeInfo) =>
  config?.contactDetails?.firstName || config?.contactDetails?.lastName
    ? `${config?.contactDetails?.firstName} ${config?.contactDetails?.lastName}`
    : '--';

const getBrokerIdFormConfigSpecific = (selectedCarrier: string, action: string): IFormConfig[] => {
  const { agencyNetworkMetadata, userType } = useConfigurationStore.getState();
  // TODO: this will be change in future, this masking attribute come from the backend and according that we need to mask the configuration. CFP-10358
  const maskAttributesForAmtrust =
    ['nbs', 'trustadvisors'].includes(agencyNetworkMetadata?.clientAppName || '') &&
    userType === AgentPortalUserType.AGENCY &&
    action === Actions.EDIT
      ? { type: INPUT_TYPES.PASSWORD, visibilityToggle: false, disabled: true }
      : {};
  switch (selectedCarrier) {
    case Carrier.AMTRUST:
      return [
        {
          dataIndex: 'configuration.userName',
          label: 'User Name',
          placeholder: 'User Name',
          prefix: <UserOutlined />,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                if (value?.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter user name.'));
              },
            },
          ],
          tooltip: 'Required by AmTrust to identify your Agency as the Broker of Record for policies',
          ...maskAttributesForAmtrust,
        },
        {
          dataIndex: 'configuration.password',
          label: 'Password',
          placeholder: '**********',
          prefix: <LockOutlined />,
          type: INPUT_TYPES.PASSWORD,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                if (value?.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter password.'));
              },
            },
          ],
          tooltip: 'Required by AmTrust to identify your Agency as the Broker of Record for policies',
          ...maskAttributesForAmtrust,
        },
      ];
    case Carrier.LIBERTYMUTUAL:
      return [
        {
          dataIndex: 'configuration.niprId',
          label: 'NPN Code',
          placeholder: '123456',
          prefix: <LockOutlined />,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                if (value?.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter NPN Code.'));
              },
            },
          ],
          tooltip: 'The NPN number of the agent appointed with Liberty Mutual',
        },
        {
          dataIndex: 'configuration.contractNumber',
          label: 'Agency Code',
          placeholder: '1234567',
          prefix: <LockOutlined />,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                value = value?.toString()?.trim();
                if (!value) {
                  return Promise.reject(new Error('Please enter agency code.'));
                } else if (value && value.length >= 8) {
                  return Promise.reject(new Error('Please enter valid agency code.'));
                }
                return Promise.resolve();
              },
            },
          ],
          tooltip: 'The 7-digit numeric code assigned by Liberty Mutual to your agency ',
        },
      ];
    case Carrier.CHUBB:
      return [
        {
          dataIndex: 'configuration.contractNumber',
          label: 'Contract Number',
          placeholder: '1234567',
          prefix: <LockOutlined />,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                if (value?.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter contract number.'));
              },
            },
          ],
          tooltip: 'This is a code assigned to your agency by Chubb',
        },
      ];
    case Carrier.TRAVELERS:
      return [
        {
          dataIndex: 'configuration.producerCode',
          label: 'Producer Code',
          placeholder: '12A45C',
          prefix: <LockOutlined />,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                if (!value?.trim()) {
                  return Promise.reject(new Error('Please enter Producer Code.'));
                }
                return Promise.resolve();
              },
            },
          ],
          tooltip:
            'Required by Travelers. This is the unique number provided to your agency by Travelers for identification',
        },
      ];
    case Carrier.EMPLOYERS:
      return [
        {
          dataIndex: 'configuration.agencyCode',
          label: 'Agency Code',
          placeholder: '1234567',
          prefix: <LockOutlined />,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                value = value?.toString()?.trim();
                if (!value) {
                  return Promise.reject(new Error('Please enter Agency Code.'));
                } else if (value && value.length !== 7) {
                  return Promise.reject(new Error('Please enter Agency Code of 7 digits.'));
                }
                return Promise.resolve();
              },
            },
          ],
          tooltip: 'The 7-digit numeric code assigned by Employers to your agency',
        },
      ];
    case Carrier.NATIONWIDE:
      return [
        // {
        //   dataIndex: 'configuration.producerCode',
        //   label: 'Producer Code',
        //   placeholder: 'XXXXXXXX-XXXXXXXX',
        //   prefix: <LockOutlined />,
        //   type: INPUT_TYPES.NATIONWIDE_PRODUCER_CODE,
        //   maxLength: 17,
        //   rules: [
        //     {
        //       required: true,
        //       validator: (_, value) => {
        //         value = normalizeProducerCode(value?.trim());
        //         if (value && value.toString().length === 17) {
        //           return Promise.resolve();
        //         }
        //         return Promise.reject(new Error('Please enter valid Producer Code.'));
        //       },
        //     },
        //   ],
        //   tooltip:
        //     'Required by Nationwide. This is the unique number provided to your agency by Nationwide for identification',
        // },
        {
          dataIndex: 'configuration.agencyCode',
          label: 'Agency Code',
          placeholder: '12345',
          type: INPUT_TYPES.NUMBER,
          prefix: <LockOutlined />,
          maxLength: 5,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                value = getOnlyNumber(value?.trim());
                if (value && value.toString()?.length === 5) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter valid Agency Code.'));
              },
            },
          ],
          tooltip: 'The 5-digit numeric code assigned by Nationwide to your agency',
        },
      ];
    case Carrier.CNA:
      return [
        {
          dataIndex: 'configuration.contractNumber',
          label: 'Contract Number',
          placeholder: 'Please enter Contract Number',
          type: INPUT_TYPES.NUMBER,
          prefix: <LockOutlined />,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                value = getOnlyNumber(value?.trim());
                if (value && value.toString().length === 6) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter valid Contract Number.'));
              },
            },
          ],
          tooltip: 'The 6-digit numeric contract number assigned by CNA to your agency',
        },
        {
          dataIndex: 'configuration.branchCode',
          label: 'Branch Code',
          placeholder: 'Please enter Branch Code',
          type: INPUT_TYPES.NUMBER,
          prefix: <LockOutlined />,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                value = getOnlyNumber(value?.trim());
                if (value && value.toString().length === 3) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter valid Branch Code.'));
              },
            },
          ],
          tooltip: 'The 3-digit numeric branch code assigned by CNA to your agency',
        },
      ];
    case Carrier.GAIG:
      return [
        {
          dataIndex: 'configuration.producerCode',
          label: 'Producer Code',
          placeholder: '12A45C',
          prefix: <LockOutlined />,
          type: INPUT_TYPES.GAIG_PRODUCER_CODE,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                if (value?.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter Producer Code.'));
              },
            },
          ],
          tooltip:
            'Required by Great American. This is the unique number provided to your agency by Great American for identification',
        },
      ];
    case Carrier.COALITION:
      return [
        {
          dataIndex: 'configuration.userName',
          label: 'User Name',
          placeholder: 'User Name',
          prefix: <UserOutlined />,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                if (value?.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter user name.'));
              },
            },
          ],
          tooltip: 'Required by Coalition to identify your Agency as the Broker of Record for policies',
        },
        {
          dataIndex: 'configuration.password',
          label: 'Password',
          placeholder: '**********',
          prefix: <LockOutlined />,
          type: INPUT_TYPES.PASSWORD,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                if (value?.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter password.'));
              },
            },
          ],
          tooltip: 'Required by Coalition to identify your Agency as the Broker of Record for policies',
        },
      ];
    case Carrier.COALITION_SURPLUS:
      return [
        {
          dataIndex: 'configuration.userName',
          label: 'User Name',
          placeholder: 'User Name',
          prefix: <UserOutlined />,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                if (value?.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter user name.'));
              },
            },
          ],
          tooltip: 'Required by Coalition (Surplus) to identify your Agency as the Broker of Record for policies',
        },
        {
          dataIndex: 'configuration.password',
          label: 'Password',
          placeholder: '**********',
          prefix: <LockOutlined />,
          type: INPUT_TYPES.PASSWORD,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                if (value?.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter password.'));
              },
            },
          ],
          tooltip: 'Required by Coalition (Surplus) to identify your Agency as the Broker of Record for policies',
        },
      ];
    case Carrier.MARKEL:
      return [
        {
          dataIndex: 'configuration.agencyId',
          label: 'Agency Id',
          placeholder: '123456',
          prefix: <LockOutlined />,
          type: INPUT_TYPES.NUMBER,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                value = value?.toString()?.trim();
                if (!value) {
                  return Promise.reject(new Error('Please enter agency Id.'));
                }
                return Promise.resolve();
              },
            },
          ],
          tooltip: 'Numeric code assigned by Markel to your agency',
        },
        {
          dataIndex: 'configuration.licensingAgentUsername',
          label: 'Licensing User Name',
          placeholder: 'Licensing User Name',
          prefix: <UserOutlined />,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                if (value?.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter licensing user name.'));
              },
            },
          ],
          tooltip: 'The agent username/email that is licensed with Markel to bind the quotes',
        },
        {
          dataIndex: 'configuration.servicingAgentUsername',
          label: 'Servicing User Name',
          placeholder: 'Servicing User Name',
          prefix: <UserOutlined />,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                if (value?.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter servicing user name.'));
              },
            },
          ],
          tooltip: 'The agent username/email that you want all communication to go to for a quote',
        },
      ];
    case Carrier.GUARD:
      return [
        {
          dataIndex: 'configuration.userCode',
          label: 'Access Key',
          placeholder: 'Access Key',
          prefix: <UserOutlined />,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                if (value?.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter Access Key.'));
              },
            },
          ],
          tooltip: 'Required by GUARD to identify your Agency as the Broker of Record for policies',
        },
        {
          dataIndex: 'configuration.password',
          label: 'Secret Key',
          placeholder: '**********',
          prefix: <LockOutlined />,
          type: INPUT_TYPES.PASSWORD,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                if (value?.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter Secret Key.'));
              },
            },
          ],
          tooltip: 'Required by GUARD to identify your Agency as the Broker of Record for policies',
        },
        {
          dataIndex: 'configuration.contractNumber',
          label: 'Contract Number',
          placeholder: 'Contract Number',
          prefix: <LockOutlined />,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                if (value?.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter Contract Number.'));
              },
            },
          ],
          tooltip: 'The contract number assigned by GUARD to your agency',
        },
        {
          dataIndex: 'configuration.isWholesaler',
          label: 'Is Wholesaler',
          type: INPUT_TYPES.SELECT,
          placeholder: 'Is Wholesaler',
          options: [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ],
          rules: [
            {
              required: true,
              message: 'Please select Is Wholesaler.',
            },
          ],
          tooltip: 'Whether the broker is for a wholesaler or a retailer',
        },
      ];
    case Carrier.MERCHANTS:
      return [
        {
          dataIndex: 'configuration.agentNumber',
          label: 'Agent Number',
          placeholder: '12345',
          prefix: <LockOutlined />,
          type: INPUT_TYPES.NUMBER,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                value = getOnlyNumber(value?.trim());
                if (value && value.toString().length === 5) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please enter valid Agent Number.'));
              },
            },
          ],
          tooltip: 'The unique 5-digit number assigned to your agency',
        },
      ];
    case Carrier.ACCIDENTFUND:
      return [
        {
          dataIndex: 'configuration.agencyCode',
          label: 'Agency Code',
          placeholder: 'AB12345',
          prefix: <LockOutlined />,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                value = value?.toString()?.trim();
                const regex = new RegExp('^[A-Z]{2}[0-9]{2,5}$');
                if (!value) {
                  return Promise.reject(new Error('Please enter Agency Code.'));
                } else if (value && !regex.test(value)) {
                  return Promise.reject(
                    new Error('Agency Code must be 2 uppercase characters followed by 2 to 5 digits.'),
                  );
                }
                return Promise.resolve();
              },
            },
          ],
          tooltip: 'The Agency Code assigned by AccidentFund to your agency',
        },
      ];
    case Carrier.COMPWEST:
      return [
        {
          dataIndex: 'configuration.agencyCode',
          label: 'Agency Code',
          placeholder: 'AB12345',
          prefix: <LockOutlined />,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                value = value?.toString()?.trim();
                const regex = new RegExp('^[A-Z]{2}[0-9]{5}$');
                if (!value) {
                  return Promise.reject(new Error('Please enter Agency Code.'));
                } else if (value && !regex.test(value)) {
                  return Promise.reject(new Error('Agency Code must be 2 uppercase characters followed by 5 digits.'));
                }
                return Promise.resolve();
              },
            },
          ],
          tooltip: 'The Agency Code assigned by Compwest to your agency',
        },
      ];
    case Carrier.COTERIE:
      return [
        {
          dataIndex: 'configuration.agencyId',
          label: 'Agency Id',
          placeholder: 'AY-16d37432-fld5-4091-a5de-80c2z7533d2e',
          prefix: <LockOutlined />,
          rules: [
            {
              required: true,
              message: 'Please enter Agency Id.',
            },
          ],
          tooltip: 'The AgencyId used to identify your agency assigned by Coterie',
        },
        {
          dataIndex: 'configuration.primaryAgencyEmail',
          label: 'Agent Email',
          placeholder: 'email@email.com',
          type: INPUT_TYPES.EMAIL,
          prefix: <LockOutlined />,
          rules: [{ required: true, pattern: getEmailRegex(), message: 'Please enter valid email address.' }],
          tooltip: 'This is the email used to login to your Coterie agent portal',
        },
      ];
    case Carrier.HISCOX:
      return [
        {
          dataIndex: 'configuration.producerCode',
          label: 'Producer Code',
          placeholder: '12A45C',
          prefix: <LockOutlined />,
          rules: [
            {
              required: true,
              validator: (_, value) => {
                if (!value?.trim()) {
                  return Promise.reject(new Error('Please enter Producer Code.'));
                }
                return Promise.resolve();
              },
            },
          ],
          tooltip: 'Required by Hiscox. This is the unique number provided to your agency by Hiscox for identification',
        },
      ];
    default:
      return [];
  }
};

const getCarrierSpecificContactInfo = (selectedCarrier: string): IFormConfig[] => {
  const formConfig: IFormConfig[] = [];

  const emailField: IFormConfig = {
    dataIndex: 'contactDetails.email',
    label: 'Email',
    placeholder: 'john@agency.com',
    type: INPUT_TYPES.EMAIL,
    prefix: <MailOutlined />,
    tooltip: (() => {
      switch (selectedCarrier) {
        case Carrier.LIBERTYMUTUAL:
          return 'Email of the agent appointed with Liberty Mutual';
        case Carrier.TRAVELERS:
          return 'This is the email associated with your Travelers account';
        case Carrier.AMTRUST:
          return 'Required by AmTrust. This is the email associated with your AmTrust account';
        case Carrier.EMPLOYERS:
          return "The email you use to login to Employers' EAAccess portal";
        case Carrier.FIRST_INSURANCE:
          return 'The email of the agent';
        default:
          return undefined;
      }
    })(),
    rules: [
      {
        required: true,
        pattern: getEmailRegex(),
        message: 'Please enter valid email address.',
      },
    ],
  };

  const firstNameField: IFormConfig = {
    dataIndex: 'contactDetails.firstName',
    label: 'First Name',
    placeholder: 'First Name',
    prefix: <UserOutlined />,
    tooltip: (() => {
      switch (selectedCarrier) {
        case Carrier.LIBERTYMUTUAL:
          return 'First name of the agent appointed with Liberty Mutual';
        case Carrier.FIRST_INSURANCE:
          return 'The first name of the agent';
        default:
          return undefined;
      }
    })(),
    rules: [
      {
        required: true,
        validator: (_, value) => {
          if (value?.trim()) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please enter first name.'));
        },
      },
    ],
  };

  const lastNameField: IFormConfig = {
    dataIndex: 'contactDetails.lastName',
    label: 'Last Name',
    placeholder: 'Last Name',
    prefix: <UserOutlined />,
    tooltip: (() => {
      switch (selectedCarrier) {
        case Carrier.LIBERTYMUTUAL:
          return 'Last name of the agent appointed with Liberty Mutual';
        case Carrier.FIRST_INSURANCE:
          return 'The last name of the agent';
        default:
          return undefined;
      }
    })(),
    rules: [
      {
        required: true,
        validator: (_, value) => {
          if (value?.trim()) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please enter last name.'));
        },
      },
    ],
  };

  switch (selectedCarrier) {
    case Carrier.AMTRUST:
    case Carrier.EMPLOYERS:
      formConfig.push(emailField);
      break;
    case Carrier.LIBERTYMUTUAL:
    case Carrier.FIRST_INSURANCE:
      formConfig.push(firstNameField);
      formConfig.push(lastNameField);
      formConfig.push(emailField);
      break;
    case Carrier.CNA:
      formConfig.push(firstNameField);
      formConfig.push(lastNameField);
      break;
    default:
      break;
  }
  return formConfig;
};

export const getBrokerIdFormConfig = ({
  selectedCarrier,
  action,
  isAgencyNetwork,
}: {
  selectedCarrier: string;
  action: string;
  isAgencyNetwork?: boolean;
}): IFormConfig[] => {
  const brokerIdFormConfigCommon: IFormConfig[] = [
    {
      dataIndex: 'configurationName',
      label: 'Label',
      placeholder: 'Label',
      prefix: <IdcardOutlined />,
      tooltip: 'A label to identify this broker configuration',
      rules: [
        {
          required: true,
          validator: (_, value) => {
            if (value?.trim()) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('Please enter label.'));
          },
        },
      ],
      disabled:
        action === Actions.ADD ? DISABLED_BROKER_CONFIG_LIST.includes(selectedCarrier as Carrier) : isAgencyNetwork,
    },
    ...getCarrierSpecificContactInfo(selectedCarrier),
  ];

  const brokerIdFormConfigSpecific = getBrokerIdFormConfigSpecific(selectedCarrier, action);
  const brokerIdFormConfig: IFormConfig[] = brokerIdFormConfigCommon.concat(brokerIdFormConfigSpecific);
  return brokerIdFormConfig;
};

export const getBrokerCodeSuccessMessage = ({ carrier, type }: { carrier?: Carrier; type: string }) => {
  switch (carrier) {
    case Carrier.CHUBB: {
      if (type === BROKER_CODE_ACTION_TYPES.ADD) {
        return BROKER_CODE_MESSAGES.CHUBB_ADD_BROKER_CODE_SUCCESS;
      }
      return BROKER_CODE_MESSAGES.CHUBB_UPDATE_BROKER_CODE_SUCCESS;
    }

    default:
      if (type === BROKER_CODE_ACTION_TYPES.ADD) {
        return BROKER_CODE_MESSAGES.ADD;
      }
      return BROKER_CODE_MESSAGES.UPDATE;
  }
};

export const getTimeToExpireForBrokerCodeToast = (carrier: Carrier) => {
  return carrier === Carrier.CHUBB ? 5 : 1.5;
};

export const getSearchString = ({
  selectedCarrier,
  brokerConfig,
}: {
  selectedCarrier: string;
  brokerConfig: IBrokerCodeInfo;
}) => {
  switch (selectedCarrier) {
    case Carrier.AMTRUST:
    case Carrier.EMPLOYERS:
      return `${brokerConfig?.configurationName} ${brokerConfig?.contactDetails?.email}`.toLowerCase();
    case Carrier.LIBERTYMUTUAL:
    case Carrier.FIRST_INSURANCE:
      return `${brokerConfig?.configurationName} ${getBrokerCodeFullName(brokerConfig)} ${
        brokerConfig?.contactDetails?.email
      }`.toLowerCase();
    case Carrier.CNA:
    case Carrier.CHUBB:
    case Carrier.GAIG:
    case Carrier.NATIONWIDE:
    case Carrier.PROGRESSIVE:
    case Carrier.TRAVELERS:
    default:
      return `${brokerConfig?.configurationName}`.toLowerCase();
  }
};
