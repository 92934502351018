import { IApplicationSummaryInfo, IPublicAPIClientInfo } from '@coverforce-platform/cf-common-api-model';
import {
  AgentPortalUserType,
  ApplicationSource,
  ApplicationStatus,
  ApplicationTag,
} from '@coverforce-platform/cf-common-types';
import { Tag } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { DefaultTheme } from 'styled-components';

import { useConfigurationStore } from '../../../components/configuration/store';
import { MenuConfig } from '../../../components/menuActionButton/interface';
import { ALLOWED_APPLICATION_TAGS, LOCAL_STORAGE_KEYS, MENU_ITEM_KEY } from '../../../globalConstants';
import { capitalizeText, getApplicationStatusColorCodeMap } from '../../../helpers/Utils';
import { ITheme } from '../../../types';
import {
  checkApplicationEligibilityForBound,
  getViewApplicationLink,
  isApplicationGeneratedFromAcord,
} from '../../../utils/application/application';
import { getApplicationStatusDisplayName } from '../../../utils/getApplicationStatusDisplayName';
import { getFromLocalStorage } from '../../../utils/getFromLocalStorage';
import { pushEvent } from '../../../utils/googleAnalytics';
import { cloneDeep, intersection, isEqual, pickBy } from '../../../utils/lodash';
import { useProfileV2Store } from '../profile/store';
import {
  APPLICATION_TABLE_KEYS,
  CHANNEL_FILTER_CUSTOM_ALL_STORE_ENTRY,
  GA_ACTION,
  GA_CATEGORY,
  GA_LABEL,
} from './constants';
import { IFilter } from './interface';
import { useApplicationsStore } from './store';
import { TagContainerStyled, TagStyled } from './styles';

export const getChannelTag = ({
  theme,
  applicationId,
  applicationSource,
  publicAPIClientName,
}: {
  theme: DefaultTheme;
  applicationId: string;
  applicationSource: ApplicationSource;
  publicAPIClientName: string;
}) => {
  return (
    <>
      <Tag
        color={getApplicationSourceColorCodeMap(theme).get(applicationSource)}
        key={`status-${applicationId}-${applicationSource}`}
      >
        {getChannelDisplayName(applicationSource)}
      </Tag>
      {publicAPIClientName && (
        <Tag
          color={getApplicationSourceColorCodeMap(theme).get(applicationSource)}
          key={`status-${applicationId}-${publicAPIClientName}`}
        >
          {publicAPIClientName}
        </Tag>
      )}
    </>
  );
};

export const getApplicationSourceColorCodeMap = (theme: DefaultTheme) => {
  const colorCodeMap: Map<ApplicationSource, string> = new Map();

  colorCodeMap.set(ApplicationSource.PORTAL, theme.custom.applicationStatus.quoting);
  colorCodeMap.set(ApplicationSource.API, theme.custom.applicationStatus.referred);

  return colorCodeMap;
};

export const getChannelDisplayName = (channel: ApplicationSource) => {
  switch (channel) {
    case ApplicationSource.PORTAL:
      return 'Portal';
    case ApplicationSource.API:
      return 'Online Store';
    default:
      return 'Portal';
  }
};

export const getChannelFilter = (publicApiClients?: IPublicAPIClientInfo[]): any => {
  const portalChannelEntry = [
    { text: getChannelDisplayName(ApplicationSource.PORTAL), value: ApplicationSource.PORTAL },
  ];

  const publicApiChannelEntries =
    publicApiClients?.map((publicApiClient: IPublicAPIClientInfo) => {
      return {
        text: `Online Store: ${publicApiClient?.clientName}`,
        value: publicApiClient?.publicAPIClientId,
      };
    }) || [];

  const publicApiAllChannelEntry = [
    {
      text: CHANNEL_FILTER_CUSTOM_ALL_STORE_ENTRY.TEXT,
      value: CHANNEL_FILTER_CUSTOM_ALL_STORE_ENTRY.VALUE,
    },
  ];

  if (publicApiChannelEntries?.length > 0) {
    return [...portalChannelEntry, ...publicApiAllChannelEntry, ...publicApiChannelEntries];
  }
  return portalChannelEntry;
};

const networkMenuConfig = (record: any, theme: ITheme): MenuConfig[] => {
  const { userType } = useConfigurationStore.getState();
  const isDisableReassignApp = theme?.config?.disabledReassignApplication?.[userType];
  return [
    {
      label: 'View Application',
      key: MENU_ITEM_KEY.VIEW,
      link: getViewApplicationLink(record),
    },
    {
      label: 'Reassign',
      key: MENU_ITEM_KEY.REASSIGN,
      disabled: isDisableReassignApp,
    },
    {
      label: 'Mark as bound',
      key: MENU_ITEM_KEY.MARK_AS_BOUND,
      showItem: checkApplicationEligibilityForBound(record?.applicationStatus?.statusCode),
    },
  ];
};

const agencyMenuConfig = (record: any): MenuConfig[] => [
  {
    label: 'View Application',
    key: MENU_ITEM_KEY.VIEW,
    link: getViewApplicationLink(record),
  },
  {
    label: 'Clone Application',
    key: MENU_ITEM_KEY.CLONE,
  },
  {
    label: 'Mark as bound',
    key: MENU_ITEM_KEY.MARK_AS_BOUND,
    showItem: checkApplicationEligibilityForBound(record?.applicationStatus?.statusCode),
  },
];

export const getMenuConfig = ({
  userType,
  record,
  theme,
}: {
  userType: AgentPortalUserType;
  record: IApplicationSummaryInfo;
  theme: ITheme;
}) => {
  return userType === AgentPortalUserType.AGENCY_NETWORK ? networkMenuConfig(record, theme) : agencyMenuConfig(record);
};

export const getFilterOptionsOfStatus = () => {
  // status options
  const filterOptionsForStatus = Object.keys(ApplicationStatus)
    ?.filter((item) => item !== ApplicationStatus.DELETED)
    ?.map((channel) => ({
      text: getApplicationStatusDisplayName(ApplicationStatus[channel as ApplicationStatus]),
      value: ApplicationStatus[channel as ApplicationStatus],
    }));

  // tags options
  const filterOptionsForTags = Object.keys(ApplicationTag)?.map((tag) => ({
    text: capitalizeText(tag),
    value: ApplicationTag[tag as ApplicationTag],
  }));

  const finalizedOptionsForTags = filterOptionsForTags?.filter((item) =>
    ALLOWED_APPLICATION_TAGS?.includes(item?.value),
  );

  return [...filterOptionsForStatus, ...finalizedOptionsForTags];
};

export const getEditColumnsPreference = () => {
  const { selectedUserProfile } = useProfileV2Store.getState();
  // check localstorage, if there is preference and its type is Array then use that
  const applicationPreference = getFromLocalStorage(LOCAL_STORAGE_KEYS.APPLICATIONS_COLUMNS)?.[
    `${selectedUserProfile?.agentProfileId}`
  ];
  if (!applicationPreference || !Array.isArray(applicationPreference)) {
    return [];
  }
  return applicationPreference;
};

export const getApplicationTag = ({
  theme,
  applicationId,
  applicationStatus,
  applicationTags,
  hideFromAcordCustomTag,
}: {
  theme: DefaultTheme;
  applicationId: string;
  applicationStatus: ApplicationStatus;
  applicationTags: ApplicationTag[];
  hideFromAcordCustomTag?: boolean;
}) => {
  const finalizedApplicationTags = applicationTags?.filter((item) => ALLOWED_APPLICATION_TAGS?.includes(item));

  return (
    <TagContainerStyled>
      {/* Application Status */}
      <TagStyled
        color={getApplicationStatusColorCodeMap(theme).get(applicationStatus)}
        key={`status-${applicationId}-${applicationStatus}`}
      >
        {getApplicationStatusDisplayName(applicationStatus)}
      </TagStyled>

      {/* From Acord Custom Tag */}
      {applicationStatus === ApplicationStatus.CREATED &&
        isApplicationGeneratedFromAcord(applicationTags) &&
        !hideFromAcordCustomTag && (
          <TagStyled
            color={getApplicationStatusColorCodeMap(theme).get(applicationStatus)}
            key={`status-${applicationId}-from-acord`}
          >
            From ACORD
          </TagStyled>
        )}

      {/* Application Tags */}
      {finalizedApplicationTags?.map((tag, index) => (
        <TagStyled
          color={getApplicationStatusColorCodeMap(theme).get(tag)}
          key={`status-${applicationId}-${index}-${tag}`}
        >
          {capitalizeText(tag)}
        </TagStyled>
      ))}
    </TagContainerStyled>
  );
};

const getEventParams = (fieldName?: string) => {
  switch (fieldName) {
    case 'accountName':
      return {
        category: GA_CATEGORY.APPLICATIONS_TABLE,
        action: GA_ACTION.SORT_BY_ACCOUNT_NAME,
        label: GA_LABEL.SORT_ACCOUNT_NAME,
      };
    case 'createdAt':
      return {
        category: GA_CATEGORY.APPLICATIONS_TABLE,
        action: GA_ACTION.SORT_BY_CREATED_DATE,
        label: GA_LABEL.SORT_CREATED_DATE,
      };
    case 'insuranceEffectiveDate':
      return {
        category: GA_CATEGORY.APPLICATIONS_TABLE,
        action: GA_ACTION.SORT_BY_EFFECTIVE_DATE,
        label: GA_LABEL.SORT_EFFECTIVE_DATE,
      };
    case 'updatedAt':
      return {
        category: GA_CATEGORY.APPLICATIONS_TABLE,
        action: GA_ACTION.SORT_BY_MODIFIED_DATE,
        label: GA_LABEL.SORT_MODIFIED_DATE,
      };
    case 'policyType':
      return {
        category: GA_CATEGORY.APPLICATIONS_TABLE,
        action: GA_ACTION.FILTER_BY_POLICY,
        label: GA_LABEL.FILTER_POLICY,
      };
    case 'applicationStatus':
      return {
        category: GA_CATEGORY.APPLICATIONS_TABLE,
        action: GA_ACTION.FILTER_BY_STATUS,
        label: GA_LABEL.FILTER_STATUS,
      };
    case 'applicationSource':
      return {
        category: GA_CATEGORY.APPLICATIONS_TABLE,
        action: GA_ACTION.FILTER_BY_CHANNEL,
        label: GA_LABEL.FILTER_CHANNEL,
      };

    default:
      break;
  }
};

export const pushTableChangeAction = ({ fieldName }: { fieldName?: any }) => {
  const eventProps = getEventParams(fieldName);
  eventProps && pushEvent(eventProps);
};

export const getFieldName = (oldFilter?: IFilter | null, newFilter?: IFilter) => {
  const differingValues = pickBy(newFilter, (value, key) => !isEqual(value, oldFilter?.[key as keyof IFilter]));
  const keys = Object.keys(differingValues)?.filter((key) => !!differingValues?.[key as keyof IFilter]);

  return keys?.[0];
};
export const getSortOrder = (
  colKey: typeof APPLICATION_TABLE_KEYS[keyof typeof APPLICATION_TABLE_KEYS],
  filters?: IFilter | null,
): SortOrder => {
  // sortBy is stored in the format 'asc(fieldKey)'
  // the below will return currentSortKey = ['(fieldKey)', 'fieldKey']
  const currentSortKey = filters?.sortBy?.match(/\(([^)]+)\)/);

  if (colKey === currentSortKey?.[1]) {
    const parts = filters?.sortBy?.split(`(${colKey})`);
    return parts && parts[0] ? (parts[0] === 'asc' ? 'ascend' : 'descend') : null;
  }

  return null;
};

export const modifyApplicationsTableFilter = (filters?: IFilter) => {
  let params = filters ? cloneDeep(filters) : {};

  // CHANNEL COLUMN Filter handling STARTS --->
  const channelFilter = params?.channel;

  // In application table we have a column name channel which consists of two informations (applicationSource, publicAPIClientId).
  // Based on the selection of the user in filter section we will send the data in the respective key. It can be either applicationSource or publicAPIClientId.
  if (channelFilter !== null && Array.isArray(channelFilter)) {
    const allOnlineStoreIndex = channelFilter?.indexOf(CHANNEL_FILTER_CUSTOM_ALL_STORE_ENTRY.VALUE);
    const portalChannelIndex = channelFilter?.indexOf(ApplicationSource.PORTAL);

    // The key for CHANNEL_FILTER_CUSTOM_ALL_STORE_ENTRY need not to be send as this is custom FE key for which the value will be all available online stores
    if (allOnlineStoreIndex !== -1) {
      params = {
        ...params,
        publicAPIClientId: useApplicationsStore?.getState()?.publicApiClients?.map((item) => item?.publicAPIClientId),
        applicationSource: undefined,
      };
    } else if (portalChannelIndex !== -1) {
      params = {
        ...params,
        applicationSource: channelFilter as ApplicationSource[],
        publicAPIClientId: undefined,
      };
    } else {
      params = {
        ...params,
        publicAPIClientId: channelFilter,
        applicationSource: undefined,
      };
    }

    delete params.channel;
  }
  // CHANNEL COLUMN Filter handling ENDS --->

  // STATUS COLUMN Filter handling STARTS --->
  if (params?.applicationStatus && params?.applicationStatus?.length > 0) {
    const tags = Object.keys(ApplicationTag);
    const appStatus = Object.keys(ApplicationStatus);

    // application applicationStatus is superset, so its consist application tags and application status both
    const applicationTags = intersection(params.applicationStatus, tags) as ApplicationTag[];
    const applicationStatus = intersection(params.applicationStatus, appStatus) as ApplicationStatus[];

    if (applicationTags.length > 0) {
      params.applicationTags = applicationTags;
    }

    if (applicationStatus.length > 0) {
      params.applicationStatus = applicationStatus;
    } else {
      //  after removing all the tags if there is no application status then we don't need to pass that field to backend.
      delete params.applicationStatus;
    }
  }
  // STATUS COLUMN Filter handling ENDS --->

  // Return the computed params.
  return params;
};
