import {
  IAdditionalInterestInfo,
  IApplicationInfo,
  ILocationInfo,
  IQuestionInfo,
  IRegexValidationInfo,
  IUnderwritingDetailsInfo,
} from '@coverforce-platform/cf-common-api-model';
import {
  ApplicationStatus,
  Carrier,
  IQuestionId,
  MUTLISELECT_UWQ_ANSWER_DELIMITER,
  PolicyType,
  QuestionType,
  StatementType,
  UWQuestionCategory,
} from '@coverforce-platform/cf-common-types';
import dayjs, { isDayjs } from 'dayjs';

import { FALSY_LABEL } from '../../components/underWritingQuestionCardV2/constants';
import { getUWQuestionV2 } from '../../externalServices/applicationV2';
import { FALSY_VALUES } from '../../globalConstants';
import { useApplicationStore } from '../../pages/v2/application/store';
import { getSelectedCarriersFromApplication, isAdditionalInterestEnabled } from '../../pages/v2/application/utils';
import { IFormInstance } from '../../ui-core/V2/cfForm/cfForm';
import { DROPDOWN_MODE } from '../../ui-core/V2/cfSelect/constants';
import { DropdownMode } from '../../ui-core/V2/cfSelect/interface';
import { convertToAntdSupportedAnswer } from '../../utils/convertToAntdSupportedAnswer';
import { cloneDeep, concat, flattenDeep, get, intersection, isObject, map, set, union, uniq } from '../../utils/lodash';
import { replaceObjectKeys } from '../../utils/replaceObjectKeys';
import { ANTD_QUESTION_TYPE } from '../applicationQuestion/constants';
import { IApplicationQuestion } from '../applicationQuestion/interface';
import { isChildQuestionRender } from '../dependentQuestion/utils';
import { AccordionTitleStyled } from '../locationBasedUnderWritingQuestion/styles';
import { addOrUpdateLocationUtil } from '../locationDetails/utils';
import { Address } from '../question/locationInputV2/interface';
import { useLocationInputStore } from '../question/locationInputV2/store';
import { isAddressEmpty, validateAddress } from '../question/locationInputV2/utils';
import { AccordionStyled } from '../underWritingQuestionCardV2/style';
import { UnderWritingDeclarationQuestion } from '../underWritingStatements/constants';
import { HeaderSectionStyled, HeaderStyled } from '../uwQuestionCardHeader/styles';
import {
  ACCORDION_TITLE,
  LOCATION_QUESTIONS_SUPPORTED_CARRIERS_FOR_WC,
  PLACEHOLDER,
  UNDERWRITING_STEP_KEYS,
  UW_QUESTION_TYPE,
  UW_STORE_KEYS,
  VALIDATION_RULES,
} from './constants';
import {
  IAntdAdditionalInterestQuestion,
  IAntdLocationQuestion,
  IUnderWritingQuestionsStore,
  IUwFormAnswers,
} from './interface';
import { useUnderWritingQuestionStore } from './store';

export const isLocationUwQuestionFetched = () => {
  const { applicationData, policyType } = useApplicationStore.getState();

  return (
    policyType === PolicyType.BOP ||
    policyType === PolicyType.CGL ||
    (policyType === PolicyType.WC &&
      intersection(getSelectedCarriersFromApplication(applicationData), LOCATION_QUESTIONS_SUPPORTED_CARRIERS_FOR_WC)
        .length > 0)
  );
};

export const isAdditionalInterestUwQuestionDisplay = () => {
  const { policyType } = useApplicationStore.getState();
  const selectedCarrier = getCurrentUwStepCarrier();

  return policyType === PolicyType.CGL && selectedCarrier === Carrier.FIRST_INSURANCE;
};

export const isLocationUwQuestionDisplay = (currentCarrier: Carrier) => {
  const { policyType } = useApplicationStore.getState();
  return (
    policyType === PolicyType.BOP ||
    policyType === PolicyType.CGL ||
    (policyType === PolicyType.WC && LOCATION_QUESTIONS_SUPPORTED_CARRIERS_FOR_WC.includes(currentCarrier))
  );
};

export const updateLocationsUwAnswers = async (locationsUwPayload: ILocationInfo[]) => {
  const { applicationData } = useApplicationStore.getState();
  await Promise.all(
    locationsUwPayload?.map(async (locationPayload) => {
      await addOrUpdateLocationUtil({
        locationPayload,
        applicationData: applicationData!,
      });
    }),
  );
};

export const getCurrentUwStepCarrier = (): Carrier => {
  const { currentSubStepIndex, selectedCarriersInfo } = useApplicationStore.getState();
  const currentCarrier = selectedCarriersInfo?.[currentSubStepIndex]?.carrierId || '';
  return currentCarrier;
};

export const addRegexValidation = (
  question: IApplicationQuestion,
  backendValidationRule: IRegexValidationInfo,
): IApplicationQuestion => {
  if (!backendValidationRule?.regexExpression) {
    return question;
  }
  let backendRegex = backendValidationRule?.regexExpression;

  if (backendRegex.startsWith('/') && backendRegex.endsWith('/')) {
    backendRegex = backendRegex?.slice(1, -1);
  }

  if (question.type?.toLowerCase() === ANTD_QUESTION_TYPE.DROPDOWN && question?.mode === DROPDOWN_MODE.TAGS) {
    const existingRules = question.rules ? question.rules : [];
    question.rules = [
      ...existingRules,
      {
        validator: (_: any, values: any) => {
          if (!values) {
            return Promise.resolve();
          }
          const regex = new RegExp(backendRegex);

          const isValid = values?.every((value: string) => {
            return value && regex.test(value);
          });

          if (isValid) {
            return Promise.resolve();
          } else {
            return Promise.reject(backendValidationRule?.validationErrorMessage);
          }
        },
      },
    ];
  } else {
    const regexRule = {
      pattern: new RegExp(backendRegex),
      message: backendValidationRule?.validationErrorMessage,
    };
    if (Array.isArray(question.rules)) {
      question.rules.push(regexRule);
    } else {
      question.rules = [regexRule];
    }
  }
  return question;
};

const addValidationRules = (question: IApplicationQuestion): IApplicationQuestion => {
  if (question && question.validations && question.validations.length > 0) {
    question.validations.forEach((item: any) => {
      if (question?.type === UW_QUESTION_TYPE.ADDRESS && item?.validationType !== VALIDATION_RULES.REQUIRED) {
        return question;
      }
      switch (item?.validationType) {
        case VALIDATION_RULES.REQUIRED: {
          const rule =
            question?.type === UW_QUESTION_TYPE.ADDRESS
              ? {
                  required: true,
                  validator(_: any, value: any) {
                    if (!validateAddress(value)) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                }
              : {
                  required: true,
                  validator: (_: any, value: string) => {
                    let isError = false;
                    // if its dropdown
                    if (Array.isArray(value)) {
                      isError = value?.length <= 0;
                    }
                    // if its Date type
                    else if (isDayjs(value)) {
                      isError = false;
                    }
                    // if its normal string
                    else {
                      isError = !value?.trim();
                    }
                    if (isError) {
                      return Promise.reject(item?.validationErrorMessage);
                    }
                    return Promise.resolve();
                  },
                };
          if (Array.isArray(question?.rules)) {
            question.rules.push(rule);
          } else {
            question.rules = [rule];
          }
          break;
        }
        case VALIDATION_RULES.REGEX: {
          question = addRegexValidation(question, item);

          break;
        }
        case VALIDATION_RULES.PERMISSIBLE:
          switch (item?.permissibleValuesType) {
            // type of permissible
            case VALIDATION_RULES.RANGE:
              switch (question.type?.toLowerCase()) {
                // question type is date
                case ANTD_QUESTION_TYPE.DATE:
                  switch (question.subtype?.toLowerCase()) {
                    // and subtype is year
                    case ANTD_QUESTION_TYPE.YEAR:
                      question.disabledDate = (date: dayjs.Dayjs): boolean =>
                        date.isBefore(dayjs(item?.permissibleValues?.min)) ||
                        date.isSameOrAfter(
                          item?.permissibleValues?.max === 'CurrentYear'
                            ? dayjs().format('YYYY')
                            : dayjs(item?.permissibleValues?.max).format('YYYY'),
                        );
                      break;
                    case ANTD_QUESTION_TYPE.DATE:
                    default:
                      question.disabledDate = (date: dayjs.Dayjs): boolean =>
                        date.isBefore(dayjs(item?.permissibleValues?.min)) ||
                        date.isSameOrAfter(
                          item?.permissibleValues?.max === 'CurrentDate'
                            ? dayjs().format()
                            : dayjs(item?.permissibleValues?.max).format(),
                        );
                      break;
                  }
                  break;
                case ANTD_QUESTION_TYPE.NUMBER: {
                  const existingRules = question.rules ? question.rules : [];
                  question.rules = [
                    ...existingRules,
                    {
                      validator(_: any, val: any) {
                        if (FALSY_VALUES.includes(val)) {
                          return Promise.resolve();
                        }
                        const value = Number(val);
                        const min = Number(item?.permissibleValues?.min || 0);
                        const max = Number(item?.permissibleValues?.max || 0);

                        const permissibleMin = Boolean(item?.permissibleValues?.min);
                        const permissibleMax = Boolean(item?.permissibleValues?.max);

                        if (permissibleMin && permissibleMax) {
                          if (value < min || value > max) {
                            return Promise.reject(item?.validationErrorMessage);
                          }
                        } else if (permissibleMin) {
                          if (value < min) {
                            return Promise.reject(item?.validationErrorMessage);
                          }
                        } else if (permissibleMax) {
                          if (value > max) {
                            return Promise.reject(item?.validationErrorMessage);
                          }
                        }
                        return Promise.resolve();
                      },
                    },
                  ];
                  break;
                }
                default:
                  break;
              }
              break;
            case VALIDATION_RULES.LENGTH: {
              const rule = {
                max: parseInt(item?.permissibleValues),
                message: item?.validationErrorMessage,
              };
              if (Array.isArray(question?.rules)) {
                question.rules.push(rule);
              } else {
                question.rules = [rule];
              }

              break;
            }
            default:
              break;
          }
          break;
        default:
          break;
      }
    });
  }

  return question;
};

const changeTypeOfQuestion = (question: IApplicationQuestion): IApplicationQuestion => {
  switch (question?.type) {
    case UW_QUESTION_TYPE.YEAR:
      question.type = ANTD_QUESTION_TYPE.DATE;
      question.subtype = ANTD_QUESTION_TYPE.YEAR;
      return question;
    case UW_QUESTION_TYPE.MONTH:
      question.type = ANTD_QUESTION_TYPE.DATE;
      question.subtype = ANTD_QUESTION_TYPE.MONTH;
      return question;
    case UW_QUESTION_TYPE.TEXT:
      question.type = ANTD_QUESTION_TYPE.STRING;
      return question;
    case UW_QUESTION_TYPE.SINGLESELECTDROPDOWN:
      question.type = ANTD_QUESTION_TYPE.DROPDOWN;
      question.filterOption = (input: string, option: any) =>
        (option?.label as unknown as string)?.toLowerCase()?.includes(input?.toLowerCase());
      return question;
    case UW_QUESTION_TYPE.NUMBER:
      question.type = ANTD_QUESTION_TYPE.NUMBER;
      return question;
    case UW_QUESTION_TYPE.MULTISELECTDROPDOWN:
      question.type = ANTD_QUESTION_TYPE.DROPDOWN;
      question.mode = DROPDOWN_MODE.MULTIPLE;
      question.filterOption = (input: string, option: any) =>
        (option?.label as unknown as string)?.toLowerCase()?.includes(input?.toLowerCase());
      return question;
    case UW_QUESTION_TYPE.MULTIINPUTTEXT:
      question.type = ANTD_QUESTION_TYPE.DROPDOWN;
      question.mode = DROPDOWN_MODE.TAGS;
      question.filterOption = (input: string, option: any) =>
        (option?.label as unknown as string)?.toLowerCase()?.includes(input?.toLowerCase());
      return question;
    case UW_QUESTION_TYPE.PHONENUMBER:
      question.type = ANTD_QUESTION_TYPE.PHONE;
      return question;
    case UW_QUESTION_TYPE.DECIMALNUMBER:
      question.type = ANTD_QUESTION_TYPE.NUMBER;
      question.allowDecimals = true;
      return question;

    // .... if any other question need to change type then it goes here
    default:
      return question;
  }
};

export const convertQuestionToAntdQuestionFormat = (question: IQuestionInfo): IApplicationQuestion => {
  /**
   * replace keys With antd supported keys.
   * ApiResponseKey => AntdSupportKey
   */
  const keysMap: { [key: string]: string } = {
    questionType: 'type',
    questionText: 'name',
    questionId: 'dataIndex',
    answerOptions: 'options',
    answerOptionText: 'label',
    answerOptionValue: 'value',
  };

  const questionWithAntdKeys = replaceObjectKeys(question, keysMap) as IApplicationQuestion;

  // change type value if needed, EXAMPLE: if type is "YEAR" or "MONTH" Then we change to that type "date" and add sub type "YEAR" or "MONTH". So date component load correctly on screen
  const questionWithCustomType = changeTypeOfQuestion(questionWithAntdKeys);

  // add validation rules in question if its required.
  const antdSupportedQuestion = addValidationRules(questionWithCustomType);

  if (question?.questionType === QuestionType.SINGLESELECTDROPDOWN) {
    antdSupportedQuestion.placeholder = PLACEHOLDER.SELECT_PLACEHOLDER;
  }

  return antdSupportedQuestion;
};

export const processUWQuestion = ({
  questions,
}: {
  questions: IQuestionInfo[];
}): { [k: string]: IApplicationQuestion[] } => {
  if (!Array.isArray(questions)) {
    return {};
  }

  const listCarriers = union(flattenDeep(questions?.map((question: IQuestionInfo) => question?.carriers || [])));

  // Carrier => questions
  const questionResponse: { [key: string]: IApplicationQuestion[] } = {};

  questions.forEach((question) => {
    const carriers = uniq(question?.carriers);

    carriers?.forEach((carrier) => {
      if (listCarriers?.includes(carrier)) {
        const updatedQues = convertQuestionToAntdQuestionFormat(question);
        if (questionResponse[carrier]) {
          questionResponse[carrier].push(updatedQues);
        } else {
          questionResponse[carrier] = concat([updatedQues]);
        }
      }
    });
  });

  return questionResponse;
};

export const getConvertedAnswersForLocation = ({
  formAnswers,
  underWritingAnswers,
  currentCarrier,
  antdLocationQuestionData,
}: {
  formAnswers: IUwFormAnswers;
  underWritingAnswers: {
    application: { [k: string]: string };
    locations: { [k: string]: { [k: string]: string } };
    additionalInterest: { [k: string]: { [k: string]: string } };
  };
  currentCarrier: string;
  antdLocationQuestionData?: IAntdLocationQuestion[];
}) => {
  formAnswers?.locations &&
    Object.keys(formAnswers.locations).forEach((locationIndex) => {
      const locationDetail = antdLocationQuestionData?.find(
        (locDetail) => locDetail?.location?.locationId === locationIndex,
      );
      if (!locationDetail) {
        return;
      }
      underWritingAnswers = {
        ...underWritingAnswers,
        locations: {
          ...underWritingAnswers?.locations,
          [locationIndex]: {
            ...convertToAntdSupportedAnswer(
              get(formAnswers, [UNDERWRITING_STEP_KEYS.LOCATIONS, locationIndex, currentCarrier], {}),
              locationDetail.questions ? locationDetail.questions[currentCarrier] || [] : [],
            ),
          },
        },
      };
    });

  return underWritingAnswers;
};
const getConvertedAnswersForAdditionalInterest = ({
  formAnswers,
  underWritingAnswers,
  currentCarrier,
  antdAdditionalInterestQuestionData,
}: {
  formAnswers: IUwFormAnswers;
  underWritingAnswers: {
    application: { [k: string]: string };
    locations: { [k: string]: { [k: string]: string } };
    additionalInterest: { [k: string]: { [k: string]: string } };
  };
  currentCarrier: string;
  antdAdditionalInterestQuestionData?: IAntdAdditionalInterestQuestion[];
}) => {
  formAnswers?.additionalInterest &&
    Object.keys(formAnswers.additionalInterest).forEach((additionalInterestId) => {
      const additionalInterestDetail = antdAdditionalInterestQuestionData?.find(
        (additionalInterest) => additionalInterest?.additionalInterest?.additionalInterestId === additionalInterestId,
      );
      if (!additionalInterestDetail) {
        return;
      }
      underWritingAnswers = {
        ...underWritingAnswers,
        additionalInterest: {
          ...underWritingAnswers?.additionalInterest,
          [additionalInterestId]: {
            ...convertToAntdSupportedAnswer(
              get(formAnswers, [UNDERWRITING_STEP_KEYS.ADDITIONAL_INTEREST, additionalInterestId, currentCarrier], {}),
              additionalInterestDetail?.questions ? additionalInterestDetail?.questions?.[currentCarrier] || [] : [],
            ),
          },
        },
      };
    });

  return underWritingAnswers;
};
export const getConvertedAnswers = (formAnswers: IUwFormAnswers, currentCarrier: string) => {
  const { antdGeneralQuestionData, antdLocationQuestionData, antdAdditionalInterestQuestionData } =
    useUnderWritingQuestionStore.getState();

  let underWritingAnswers: {
    application: { [k: string]: string };
    locations: { [k: string]: { [k: string]: string } };
    additionalInterest: { [k: string]: { [k: string]: string } };
  } = { application: {}, locations: {}, additionalInterest: {} };
  // convert moment object to date
  // 1. convert application level question answer moment object to date
  underWritingAnswers = {
    ...underWritingAnswers,
    application: {
      ...convertToAntdSupportedAnswer(
        get(formAnswers, [UNDERWRITING_STEP_KEYS.APPLICATION, currentCarrier], {}),
        antdGeneralQuestionData ? antdGeneralQuestionData[currentCarrier] || [] : [],
      ),
    },
  };

  // 2. convert location level question answer moment object to date
  underWritingAnswers = getConvertedAnswersForLocation({
    formAnswers,
    underWritingAnswers,
    currentCarrier,
    antdLocationQuestionData,
  });

  // 3. convert additional interest level question answer moment object to date
  underWritingAnswers = getConvertedAnswersForAdditionalInterest({
    formAnswers,
    underWritingAnswers,
    currentCarrier,
    antdAdditionalInterestQuestionData,
  });

  return underWritingAnswers;
};

export const convertAnswerToApiSupportedAnswerForApp = (
  uwQuestionAndAnswer: {
    application: IUnderwritingDetailsInfo[];
    locations: { [key: string]: IUnderwritingDetailsInfo[] };
    additionalInterest: { [key: string]: IUnderwritingDetailsInfo[] };
  },
  underWritingAnswers: {
    application: { [k: string]: string | undefined };
    locations: { [k: string]: { [k: string]: string | undefined } };
    additionalInterest: { [k: string]: { [k: string]: string | undefined } };
  },
) => {
  const localUwQuestionAndAnswer = { ...uwQuestionAndAnswer };
  map(underWritingAnswers?.application, (value: any, key) => {
    if (!FALSY_VALUES.includes(value)) {
      if (Array.isArray(value)) {
        localUwQuestionAndAnswer.application = [
          ...localUwQuestionAndAnswer.application,
          { questionId: key, answer: value?.join(MUTLISELECT_UWQ_ANSWER_DELIMITER) },
        ];
      } else {
        localUwQuestionAndAnswer.application = [
          ...localUwQuestionAndAnswer.application,
          { questionId: key, answer: value?.toString()?.trim() },
        ];
      }
    }
  });

  return localUwQuestionAndAnswer;
};

export const convertAnswerToApiSupportedAnswerForLocation = (
  uwQuestionAndAnswer: {
    application: IUnderwritingDetailsInfo[];
    locations: { [key: string]: IUnderwritingDetailsInfo[] };
    additionalInterest: { [key: string]: IUnderwritingDetailsInfo[] };
  },
  underWritingAnswers: {
    application: { [k: string]: string | undefined };
    locations: { [k: string]: { [k: string]: string | undefined } };
    additionalInterest: { [k: string]: { [k: string]: string | undefined } };
  },
) => {
  const localUwQuestionAndAnswer = { ...uwQuestionAndAnswer };
  map(underWritingAnswers?.locations, (location, locationKey) => {
    let ans: IUnderwritingDetailsInfo[] = [];
    map(location, (value: any, key) => {
      if (![undefined, null].includes(value)) {
        if (Array.isArray(value)) {
          ans = [...ans, { questionId: key, answer: value?.join(MUTLISELECT_UWQ_ANSWER_DELIMITER) }];
        } else {
          ans = [...ans, { questionId: key, answer: value?.toString()?.trim() }];
        }
      }
    });
    localUwQuestionAndAnswer.locations = {
      ...localUwQuestionAndAnswer.locations,
      [locationKey]: ans,
    };
  });

  return localUwQuestionAndAnswer;
};

export const convertAnswerToApiSupportedAnswerForAdditionalInterest = (
  uwQuestionAndAnswer: {
    application: IUnderwritingDetailsInfo[];
    locations: { [key: string]: IUnderwritingDetailsInfo[] };
    additionalInterest: { [key: string]: IUnderwritingDetailsInfo[] };
  },
  underWritingAnswers: {
    application: { [k: string]: string | undefined };
    locations: { [k: string]: { [k: string]: string | undefined } };
    additionalInterest: { [k: string]: { [k: string]: string | undefined } };
  },
) => {
  const additionalInterestUwQuestionAndAnswer = { ...uwQuestionAndAnswer };
  map(underWritingAnswers?.additionalInterest, (additionalInterestDetails, additionalInterestId) => {
    let ans: IUnderwritingDetailsInfo[] = [];
    map(additionalInterestDetails, (value: any, key) => {
      if (![undefined, null].includes(value)) {
        if (Array.isArray(value)) {
          ans = [...ans, { questionId: key, answer: value?.join(MUTLISELECT_UWQ_ANSWER_DELIMITER) }];
        } else {
          ans = [...ans, { questionId: key, answer: value?.toString()?.trim() }];
        }
      }
    });
    additionalInterestUwQuestionAndAnswer.additionalInterest = {
      ...additionalInterestUwQuestionAndAnswer.additionalInterest,
      [additionalInterestId]: ans,
    };
  });

  return additionalInterestUwQuestionAndAnswer;
};

export const convertAnswerToApiSupportedAnswer = (underWritingAnswers: {
  application: { [k: string]: string | undefined };
  locations: { [k: string]: { [k: string]: string | undefined } };
  additionalInterest: { [k: string]: { [k: string]: string | undefined } };
}): {
  application: IUnderwritingDetailsInfo[];
  locations: { [key: string]: IUnderwritingDetailsInfo[] };
  additionalInterest: { [key: string]: IUnderwritingDetailsInfo[] };
} => {
  let uwQuestionAndAnswer: {
    application: IUnderwritingDetailsInfo[];
    locations: { [key: string]: IUnderwritingDetailsInfo[] };
    additionalInterest: { [key: string]: IUnderwritingDetailsInfo[] };
  } = { application: [], locations: {}, additionalInterest: {} };

  uwQuestionAndAnswer = convertAnswerToApiSupportedAnswerForApp(uwQuestionAndAnswer, underWritingAnswers);

  uwQuestionAndAnswer = convertAnswerToApiSupportedAnswerForLocation(uwQuestionAndAnswer, underWritingAnswers);

  uwQuestionAndAnswer = convertAnswerToApiSupportedAnswerForAdditionalInterest(
    uwQuestionAndAnswer,
    underWritingAnswers,
  );

  return uwQuestionAndAnswer;
};

const filterOutDifferentCarrierAnswer = ({
  selectedCarriers,
  currentCarrier,
  userAnswers,
  alreadyGivenAnswer,
  questionData,
}: {
  selectedCarriers: Carrier[];
  currentCarrier: string;
  userAnswers: IUnderwritingDetailsInfo[];
  alreadyGivenAnswer?: IUnderwritingDetailsInfo[];
  questionData?: { [key: string]: IApplicationQuestion[] };
}) => {
  let questionIdOfDifferentCarrier: string[] = [];
  selectedCarriers?.forEach((carrierId: Carrier) => {
    if (carrierId !== currentCarrier && questionData) {
      questionData?.[carrierId]?.forEach((question: IApplicationQuestion) => {
        if (question?.dataIndex) {
          questionIdOfDifferentCarrier = [...questionIdOfDifferentCarrier, question.dataIndex];
        }
      });
    }
  });

  let underwritingQuestionAndAnswer: IUnderwritingDetailsInfo[] = [];

  if (Array.isArray(alreadyGivenAnswer)) {
    const oldUwFilteredAnswers = alreadyGivenAnswer?.filter((answer: IUnderwritingDetailsInfo) =>
      questionIdOfDifferentCarrier.includes(answer.questionId),
    );
    underwritingQuestionAndAnswer = [...oldUwFilteredAnswers, ...userAnswers];
  } else {
    underwritingQuestionAndAnswer = [...userAnswers];
  }
  return underwritingQuestionAndAnswer;
};

export const filterDifferentCarrierAnswer = ({
  uwQuestionAndAnswer,
  currentCarrier,
}: {
  uwQuestionAndAnswer: {
    application: IUnderwritingDetailsInfo[];
    locations: { [key: string]: IUnderwritingDetailsInfo[] };
    additionalInterest: { [key: string]: IUnderwritingDetailsInfo[] };
  };
  currentCarrier: string;
}) => {
  const { antdGeneralQuestionData, antdLocationQuestionData, antdAdditionalInterestQuestionData } =
    useUnderWritingQuestionStore.getState();
  const { applicationData } = useApplicationStore.getState();
  const locations = applicationData?.locationDetails || [];
  const additionalInterestDetails = applicationData?.additionalInterestDetails || [];
  const selectedCarriers = getSelectedCarriersFromApplication(applicationData);

  // filter out Different carrier answer for general questions
  const generalFilteredQuestion = filterOutDifferentCarrierAnswer({
    selectedCarriers,
    currentCarrier,
    userAnswers: uwQuestionAndAnswer.application,
    alreadyGivenAnswer: applicationData?.underwritingDetails,
    questionData: antdGeneralQuestionData,
  });

  // filter out Different carrier answer for location questions
  let locationBasedAnswer: { [k: string]: IUnderwritingDetailsInfo[] } = {};

  antdLocationQuestionData?.forEach((locationDetail) => {
    const location = locations?.find((loc) => loc?.locationId === locationDetail?.location?.locationId);
    if (!location) {
      return;
    }
    const locationFilteredQuestion = filterOutDifferentCarrierAnswer({
      selectedCarriers,
      currentCarrier,
      userAnswers: uwQuestionAndAnswer.locations[locationDetail.location.locationId || ''] || [],
      alreadyGivenAnswer: location.locationUWInformation,
      questionData: locationDetail.questions,
    });
    locationBasedAnswer = {
      ...locationBasedAnswer,
      [location.locationId || '']: locationFilteredQuestion,
    };
  });

  // filter out Different carrier answer for additional interest questions
  let additionalInterestBasedAnswer: { [k: string]: IUnderwritingDetailsInfo[] } = {};

  antdAdditionalInterestQuestionData?.forEach((additionalInterest) => {
    const insuredDetails = additionalInterestDetails?.find(
      (insured) => insured?.additionalInterestId === additionalInterest?.additionalInterest?.additionalInterestId,
    );
    if (!insuredDetails) {
      return;
    }
    const additionalInterestFilteredQuestion = filterOutDifferentCarrierAnswer({
      selectedCarriers,
      currentCarrier,
      userAnswers: uwQuestionAndAnswer.additionalInterest?.[insuredDetails?.additionalInterestId || ''] || [],
      alreadyGivenAnswer: insuredDetails.additionalInterestUWInformation,
      questionData: additionalInterest?.questions,
    });
    additionalInterestBasedAnswer = {
      ...additionalInterestBasedAnswer,
      [insuredDetails.additionalInterestId || '']: additionalInterestFilteredQuestion,
    };
  });

  return {
    applicationPayload: {
      ...applicationData,
      underwritingDetails: generalFilteredQuestion,
    },
    locationBasedAnswer,
    additionalInterestBasedAnswer,
  };
};

const convertAntdSupportedAnswer = (
  selectedCarrier: string,
  answerFromApis: IUnderwritingDetailsInfo[],
  questionData?: { [key: string]: IApplicationQuestion[] },
) => {
  if (questionData) {
    if (selectedCarrier) {
      const underwritingDetails = answerFromApis;

      if (Array.isArray(underwritingDetails) && underwritingDetails.length > 0) {
        // it contain all the question answer combination which present in db
        let underWritingAnswer: {
          questionId?: IQuestionId;
          value?: string;
        } = {};

        // it contain all the question answer combination which present in current underwriting screen
        let underWritingAnswerPresentInScreen: {
          questionId?: IQuestionId;
          value?: string;
        } = {};

        underwritingDetails?.forEach((item) => {
          const question = questionData?.[selectedCarrier]?.find(
            (ques: IApplicationQuestion) => ques.dataIndex === item.questionId,
          );
          if (question) {
            if (question?.type?.toLowerCase() === ANTD_QUESTION_TYPE.DATE) {
              underWritingAnswer = {
                ...underWritingAnswer,
                [item.questionId]: item?.answer ? dayjs(item.answer) : undefined,
              };
            } else if (
              question?.type?.toLowerCase() === ANTD_QUESTION_TYPE.DROPDOWN &&
              [DROPDOWN_MODE.MULTIPLE, DROPDOWN_MODE.TAGS].includes(question?.mode as DropdownMode)
            ) {
              underWritingAnswer = {
                ...underWritingAnswer,
                [item.questionId]: item?.answer ? item?.answer?.split(MUTLISELECT_UWQ_ANSWER_DELIMITER) : undefined,
              };
            } else if (question?.type?.toLowerCase() === ANTD_QUESTION_TYPE.ADDRESS) {
              let value;
              if (item?.answer) {
                try {
                  value = JSON.parse(item?.answer);
                } catch (err) {
                  // Error while parsing value in address type question
                  // No need to handle this.
                }
              }
              underWritingAnswer = {
                ...underWritingAnswer,
                [item.questionId]: value,
              };
            } else {
              underWritingAnswer = {
                ...underWritingAnswer,
                [item.questionId]: item?.answer,
              };
            }
          }
        });

        // it is basically intersection between db uw answer and newly fetched question
        questionData[selectedCarrier]?.map((question) => {
          if (question.dataIndex && underWritingAnswer.hasOwnProperty(question.dataIndex)) {
            underWritingAnswerPresentInScreen = {
              ...underWritingAnswerPresentInScreen,
              [question.dataIndex]: get(underWritingAnswer, [question.dataIndex]),
            };
          }

          return null;
        });

        return underWritingAnswerPresentInScreen;
      }
    }
  }
};

export const getAntdSupportedAnswerFormatForLocation = (selectedCarrier: string) => {
  let locationAnswer = {};
  const { applicationData } = useApplicationStore.getState();
  const { antdLocationQuestionData } = useUnderWritingQuestionStore.getState();

  applicationData?.locationDetails?.map((location) => {
    const filteredLocation = antdLocationQuestionData?.find(
      (locationDetails) => locationDetails?.location?.locationId === location?.locationId,
    );
    if (!filteredLocation) {
      return;
    }

    locationAnswer = {
      ...locationAnswer,
      [location.locationId || '']: {
        [selectedCarrier]: convertAntdSupportedAnswer(
          selectedCarrier,
          location.locationUWInformation || [],
          filteredLocation?.questions,
        ),
      },
    };
  });

  return locationAnswer;
};

export const getAntdSupportedAnswerFormatForAdditionalInterest = (selectedCarrier: string) => {
  let additionalInterestAnswer = {};
  const { applicationData } = useApplicationStore.getState();
  const { antdAdditionalInterestQuestionData } = useUnderWritingQuestionStore.getState();
  applicationData?.additionalInterestDetails?.map((additionalInterest) => {
    const filteredAdditionalInterest = antdAdditionalInterestQuestionData?.find(
      (additionalInterestInfo) =>
        additionalInterestInfo?.additionalInterest?.additionalInterestId === additionalInterest?.additionalInterestId,
    );
    if (!filteredAdditionalInterest) {
      return;
    }

    additionalInterestAnswer = {
      ...additionalInterestAnswer,
      [additionalInterest.additionalInterestId || '']: {
        [selectedCarrier]: convertAntdSupportedAnswer(
          selectedCarrier,
          additionalInterest?.additionalInterestUWInformation || [],
          filteredAdditionalInterest?.questions,
        ),
      },
    };
  });

  return additionalInterestAnswer;
};

export const addUWStatementAnswerInApplicationAnswer = (
  selectedCarrier: string,
  applicationAnswer?: IUnderwritingDetailsInfo,
) => {
  const { applicationData } = useApplicationStore.getState();
  let localApplicationAnswer = applicationAnswer ? { ...applicationAnswer } : {};
  if (
    applicationData?.applicationStatus?.statusCode &&
    ![ApplicationStatus.CREATED, ApplicationStatus.IN_PROGRESS].includes(applicationData.applicationStatus.statusCode)
  ) {
    localApplicationAnswer = {
      ...localApplicationAnswer,
      [UnderWritingDeclarationQuestion[0].dataIndex]: 'true',
    };
  }

  const uwStatementAnswer = applicationData?.statementDetails?.find((el) => el.carrier === selectedCarrier)?.answer;

  localApplicationAnswer = {
    ...localApplicationAnswer,
    [UnderWritingDeclarationQuestion[0].dataIndex]: uwStatementAnswer,
  };

  return localApplicationAnswer;
};

export const getAntdSupportedAnswerFormatForAutoFillUpAnswer = ({ selectedCarrier }: { selectedCarrier: string }) => {
  const { antdGeneralQuestionData } = useUnderWritingQuestionStore.getState();
  const { applicationData } = useApplicationStore.getState();

  const locationAnswer = getAntdSupportedAnswerFormatForLocation(selectedCarrier);
  const additionalInterestAnswer = getAntdSupportedAnswerFormatForAdditionalInterest(selectedCarrier);
  let applicationAnswer = convertAntdSupportedAnswer(
    selectedCarrier,
    applicationData?.underwritingDetails || [],
    antdGeneralQuestionData,
  ) as IUnderwritingDetailsInfo;

  applicationAnswer = addUWStatementAnswerInApplicationAnswer(
    selectedCarrier,
    applicationAnswer,
  ) as IUnderwritingDetailsInfo;
  return {
    application: { [selectedCarrier]: applicationAnswer },
    locations: locationAnswer,
    additionalInterest: additionalInterestAnswer,
  };
};

export const getUwLocationsPayload = (locationBasedAnswer: { [k: string]: IUnderwritingDetailsInfo[] }) => {
  const locationsUwPayload = [];
  const { applicationData } = useApplicationStore.getState();
  const locations = applicationData?.locationDetails || [];

  // save under writing answer for location level
  if (isLocationUwQuestionFetched() && applicationData && locations && locations.length > 0) {
    for (let index = 0; index < locations.length; index++) {
      const location = locations[index];
      if (location?.locationId) {
        const locationAnswer = locationBasedAnswer[location.locationId];
        const locationPayload = {
          applicationId: applicationData.applicationId,
          locationDetails: { ...location, locationUWInformation: locationAnswer },
        };

        locationsUwPayload.push(locationPayload);
      }
    }
  }

  return locationsUwPayload;
};

export const getUwAdditionalInterestPayload = (additionalInterestBasedAnswer: {
  [k: string]: IUnderwritingDetailsInfo[];
}) => {
  const additionalInterestUwPayload = [];
  const { applicationData } = useApplicationStore.getState();
  const additionalInterestDetails = applicationData?.additionalInterestDetails || [];

  // save under writing answer for location level
  if (
    isAdditionalInterestEnabled() &&
    applicationData &&
    additionalInterestDetails &&
    additionalInterestDetails.length > 0
  ) {
    for (let index = 0; index < additionalInterestDetails.length; index++) {
      const additionalInterest = additionalInterestDetails[index];
      if (additionalInterest?.additionalInterestId) {
        const additionalInterestAnswer = additionalInterestBasedAnswer[additionalInterest?.additionalInterestId];
        const additionalInterestPayload = {
          ...additionalInterest,
          additionalInterestUWInformation: additionalInterestAnswer,
        };

        additionalInterestUwPayload.push(additionalInterestPayload);
      }
    }
  }

  return additionalInterestUwPayload;
};

export const addAdditionalPayloadInAppPayload = (
  applicationPayload: IApplicationInfo,
  additionalInterestPayload?: IAdditionalInterestInfo[],
) => {
  const { applicationData, policyType } = useApplicationStore.getState();
  const { underWritingQuestionForm } = useUnderWritingQuestionStore.getState();
  const currentCarrier = getCurrentUwStepCarrier();

  const formAnswers: IUwFormAnswers = {
    ...underWritingQuestionForm?.getFieldsValue(),
  };

  const uwAnswers = get(formAnswers, [UNDERWRITING_STEP_KEYS.APPLICATION, currentCarrier], {});

  const statementDetails =
    applicationData?.statementDetails && applicationData?.statementDetails?.length > 0
      ? [...applicationData.statementDetails]
      : [];
  const statementDetailsIndex = statementDetails?.findIndex((el: any) => el?.carrier === currentCarrier);
  if (statementDetailsIndex !== -1) {
    statementDetails.splice(statementDetailsIndex, 1);
  }

  const appPayload = {
    ...applicationPayload,
    additionalInterestDetails: additionalInterestPayload,
    statementDetails:
      get(uwAnswers, UNDERWRITING_STEP_KEYS.UW_DECLARATION_QUESTION) && policyType
        ? [
            ...statementDetails,
            {
              carrier: currentCarrier as Carrier,
              statementType: StatementType.UNDERWRITING,
              policyType,
              answer: get(uwAnswers, UNDERWRITING_STEP_KEYS.UW_DECLARATION_QUESTION) as string,
            },
          ]
        : statementDetails,
  };

  return appPayload;
};

export const showUwError = (type: typeof UNDERWRITING_STEP_KEYS[keyof typeof UNDERWRITING_STEP_KEYS], error: any) => {
  return (
    <AccordionStyled
      id={`error_${type}`}
      itemKey={`address_uw_${type}_question_error`}
      defaultActiveKey={`address_uw_${type}_question_error`}
      header={
        <HeaderStyled>
          <HeaderSectionStyled>
            <AccordionTitleStyled>{ACCORDION_TITLE[type]}</AccordionTitleStyled>
          </HeaderSectionStyled>
        </HeaderStyled>
      }
    >
      {error?.map((item: any, index: number) => {
        return <div key={index}>Error: {item?.errorMessage}</div>;
      })}
    </AccordionStyled>
  );
};

export const fetchLocationUwQuestions = async () => {
  const { applicationData } = useApplicationStore.getState();
  const antdLocationQuestionData: IAntdLocationQuestion[] = [];

  const locationDetails = applicationData?.locationDetails || [];
  await Promise.all(
    locationDetails?.map(async (location, index) => {
      if (location.locationId) {
        const questions = await getUWQuestionV2(
          {
            applicationId: applicationData?.applicationId || '',
          },
          {
            locationId: location.locationId,
            category: UWQuestionCategory.LOCATION,
          },
        );

        const antdSupportedLocationQuestion = processUWQuestion({
          questions,
        });
        antdLocationQuestionData[index] = { location, questions: antdSupportedLocationQuestion };
      }
    }),
  );
  return antdLocationQuestionData;
};

export const fetchAdditionalInterestUwQuestions = async () => {
  const { applicationData } = useApplicationStore.getState();
  const antdSupportedAdditionalInterestQuestion: IAntdAdditionalInterestQuestion[] = [];

  const additionalInterestDetails = applicationData?.additionalInterestDetails || [];
  await Promise.all(
    additionalInterestDetails?.map(async (additionalInterestItem, index) => {
      if (additionalInterestItem.additionalInterestId) {
        const questions = await getUWQuestionV2(
          {
            applicationId: applicationData?.applicationId || '',
          },
          {
            entityId: additionalInterestItem.additionalInterestId,
            category: UWQuestionCategory.ADDITIONAL_INTEREST,
          },
        );

        const antdAdditionalInterestQuestion = processUWQuestion({
          questions,
        });
        antdSupportedAdditionalInterestQuestion[index] = {
          additionalInterest: additionalInterestItem,
          questions: antdAdditionalInterestQuestion,
        };
      }
    }),
  );
  return antdSupportedAdditionalInterestQuestion;
};

const hideDependentChildQuestions = ({
  filteredQuestion,
  allQuestions,
  updatedQuestions,
  parentQuestionId,
  form,
  prefixDataIndex,
}: {
  filteredQuestion: IApplicationQuestion;
  allQuestions: IApplicationQuestion[];
  updatedQuestions: IApplicationQuestion[];
  parentQuestionId: string;
  form?: IFormInstance;
  prefixDataIndex: string[];
}) => {
  let newUpdatedQuestions = cloneDeep(updatedQuestions);

  // check if childToParentQuestionsMap exist i.e. the child question has already been displayed
  if (filteredQuestion.childToParentQuestionsMap && filteredQuestion.childToParentQuestionsMap.length > 0) {
    const parentQuestionIndex = filteredQuestion.childToParentQuestionsMap?.findIndex(
      (el) => el.parentQuestionId === parentQuestionId,
    );
    const quesChildDisplayed = filteredQuestion.childToParentQuestionsMap[parentQuestionIndex]?.hasRendered;
    // check if the current parent question exists in map and remove it if exists
    if (parentQuestionIndex !== -1) {
      filteredQuestion.childToParentQuestionsMap?.splice(parentQuestionIndex, 1);
      // if the child question is displayed for the current parent
      // make the next one in the map true, so the question gets displayed for the next parent
      if (filteredQuestion.childToParentQuestionsMap?.length > 0 && quesChildDisplayed) {
        filteredQuestion.childToParentQuestionsMap[0] = {
          ...filteredQuestion.childToParentQuestionsMap[0],
          hasRendered: true,
        };
      }
    }
  }
  const questionIndex = allQuestions?.findIndex((item) => item.dataIndex === filteredQuestion.dataIndex);
  if (questionIndex !== -1) {
    newUpdatedQuestions[questionIndex] = { ...filteredQuestion };
  }

  /**
   * Scenario 1: If parent question -> child question
   *              In this case child question is hide from above the code.
   * Scenario 2: If parent question -> child parent question -> child question
   *              In this case child parent question is hide from the above code, now we need to hide all the dependent question of child parent question so we just iterate all the dependent question and check if its render in screen if yes then just we remove from the screen under this child parent question.
   *  */
  filteredQuestion?.dependentQuestions?.forEach((dependentQuestion) => {
    if (
      isChildQuestionRender({
        question: filteredQuestion,
        value: form?.getFieldValue([...prefixDataIndex, filteredQuestion.dataIndex]),
        dependentQuestion,
        form,
      }) &&
      !filteredQuestion?.childToParentQuestionsMap?.find((mappingItem) => mappingItem?.hasRendered)
    ) {
      dependentQuestion.childQuestionIds.forEach((id) => {
        const newFilteredQuestion = allQuestions?.find((item) => item.dataIndex === id);
        if (newFilteredQuestion) {
          newUpdatedQuestions = hideDependentChildQuestions({
            filteredQuestion: newFilteredQuestion,
            allQuestions,
            updatedQuestions: newUpdatedQuestions,
            parentQuestionId: filteredQuestion?.dataIndex,
            form,
            prefixDataIndex,
          });
        }
      });
    }
  });

  return newUpdatedQuestions;
};

const showDependentChildQuestions = ({
  filteredQuestion,
  allQuestions,
  updatedQuestions,
  parentQuestionId,
  form,
  prefixDataIndex,
}: {
  filteredQuestion: IApplicationQuestion;
  allQuestions: IApplicationQuestion[];
  updatedQuestions: IApplicationQuestion[];
  parentQuestionId: string;
  form?: IFormInstance;
  prefixDataIndex: string[];
}) => {
  let newUpdatedQuestions = cloneDeep(updatedQuestions);
  // check if childToParentQuestionsMap exist i.e. EITHER the child question has already been displayed
  // OR there is any other question where child questions should be displayed and has this child question
  if (filteredQuestion.childToParentQuestionsMap && filteredQuestion.childToParentQuestionsMap.length > 0) {
    const parentQuestionIndex = filteredQuestion.childToParentQuestionsMap?.findIndex(
      (el) => el.parentQuestionId === parentQuestionId,
    );
    // check if the current parent question exists in map
    // if not, push it with hasRendered as false
    // as the child question would already be displayed for any one of the parent questions
    if (parentQuestionIndex === -1) {
      filteredQuestion.childToParentQuestionsMap?.push({
        parentQuestionId,
        hasRendered: false,
      });
    }
  }
  // if childToParentQuestionsMap does not exist, create one
  else {
    filteredQuestion.childToParentQuestionsMap = [
      {
        parentQuestionId,
        hasRendered: true,
      },
    ];
  }
  const questionIndex = allQuestions?.findIndex((item) => item.dataIndex === filteredQuestion?.dataIndex);
  if (questionIndex !== -1) {
    newUpdatedQuestions[questionIndex] = { ...filteredQuestion };
  }

  /**
   * Scenario 1: If parent question -> child question
   *              In this case child question is display from above the code.
   * Scenario 2: If parent question -> child parent question -> child question
   *              In this case child parent question is display from the above code, now we need to display all the dependent question of child parent question so we just iterate all the dependent question and check if there is need to render child question in screen if yes then just we add in to the screen under this child parent question.
   *  */
  filteredQuestion?.dependentQuestions?.forEach((dependentQuestion) => {
    if (
      isChildQuestionRender({
        question: filteredQuestion,
        value: form?.getFieldValue([...prefixDataIndex, filteredQuestion.dataIndex]),
        dependentQuestion,
        form,
      })
    ) {
      dependentQuestion.childQuestionIds.forEach((id) => {
        const newFilteredQuestion = allQuestions?.find((item) => item.dataIndex === id);
        if (newFilteredQuestion) {
          newUpdatedQuestions = showDependentChildQuestions({
            filteredQuestion: newFilteredQuestion,
            allQuestions,
            updatedQuestions: newUpdatedQuestions,
            parentQuestionId: filteredQuestion?.dataIndex,
            form,
            prefixDataIndex,
          });
        }
      });
    }
  });

  return newUpdatedQuestions;
};

export const updateUWQuestions = ({
  allQuestions,
  allCarrierQuestions,
  questionKey,
  questionValue,
  storeKey,
  storeIndex,
  updateUnderWritingQuestionsByKey,
  form,
  prefixDataIndex,
}: {
  allQuestions: IApplicationQuestion[];
  allCarrierQuestions?:
    | { [key: string]: IApplicationQuestion[] }
    | IAntdLocationQuestion[]
    | IAntdAdditionalInterestQuestion[];
  questionKey?: string;
  questionValue?: any;
  storeKey?: keyof IUnderWritingQuestionsStore;
  storeIndex?: any;
  updateUnderWritingQuestionsByKey?: Function;
  form?: IFormInstance;
  prefixDataIndex: string[];
}) => {
  // get the current question whose value was changed
  const question = allQuestions?.find((item) => item.dataIndex === questionKey);
  let updatedQuestions = cloneDeep(allQuestions);
  let shouldUpdate = false;

  // we are keeping a set as for some parent questions, the child questions can be same for more than one condition
  // so in that case we don't need to check again for the child question which has already been taken into account
  const uniqueChildQuestionIds = new Set();

  question?.dependentQuestions?.forEach((dependentQuestion) => {
    if (
      isChildQuestionRender({
        question,
        value: questionValue || form?.getFieldValue([...prefixDataIndex, question.dataIndex]),
        dependentQuestion,
        form,
      })
    ) {
      dependentQuestion.childQuestionIds?.forEach((id) => {
        uniqueChildQuestionIds.add(id);
        // get the child question to be displayed
        const filteredQuestion = allQuestions?.find((item) => item.dataIndex === id);
        if (filteredQuestion) {
          updatedQuestions = showDependentChildQuestions({
            allQuestions,
            filteredQuestion,
            parentQuestionId: question.dataIndex,
            prefixDataIndex,
            updatedQuestions,
            form,
          });

          shouldUpdate = true;
        }
      });
    } else {
      dependentQuestion.childQuestionIds?.forEach((id) => {
        if (!uniqueChildQuestionIds.has(id)) {
          const filteredQuestion = allQuestions?.find((item) => item.dataIndex === id);
          if (filteredQuestion) {
            updatedQuestions = hideDependentChildQuestions({
              filteredQuestion,
              allQuestions,
              updatedQuestions,
              parentQuestionId: question.dataIndex,
              form,
              prefixDataIndex,
            });
            shouldUpdate = true;
          }
        }
      });
    }
  });

  if (shouldUpdate && updateUnderWritingQuestionsByKey && storeIndex && storeKey && allCarrierQuestions) {
    const storeValue = cloneDeep(allCarrierQuestions);
    set(storeValue, storeIndex, updatedQuestions);
    updateUnderWritingQuestionsByKey(storeKey, storeValue);
  }

  return updatedQuestions;
};

export const onGeneralUWQuestionValuesChange = ({
  selectedCarrier,
  changedValues,
  changeType,
  values,
}: {
  selectedCarrier: Carrier;
  changedValues: any;
  changeType: string;
  values: any;
}) => {
  const { antdGeneralQuestionData, updateUnderWritingQuestionsByKey } = useUnderWritingQuestionStore.getState();
  const storeKey = UW_STORE_KEYS.GENERAL_QUESTION_DATA;
  const allCarrierQuestions = antdGeneralQuestionData;
  const storeIndex = [selectedCarrier];
  const questionKey = Object.keys(changedValues?.[changeType]?.[selectedCarrier])[0];
  const questionValue = Object.values(changedValues?.[changeType]?.[selectedCarrier])[0];
  const allQuestions = antdGeneralQuestionData?.[selectedCarrier] || [];
  if (questionKey && questionValue) {
    set(values, [changeType, selectedCarrier, questionKey], questionValue);
    updateUnderWritingQuestionsByKey(UW_STORE_KEYS.FORM_VALUES, values);
    updateUnderWritingQuestionsByKey(UW_STORE_KEYS.CURRENT_FIELD_NAME, [changeType, selectedCarrier, questionKey]);
  }

  return {
    storeKey,
    allCarrierQuestions,
    storeIndex,
    questionKey,
    questionValue,
    allQuestions,
    prefixDataIndex: [UNDERWRITING_STEP_KEYS.APPLICATION, selectedCarrier],
  };
};

export const onLocationUWQuestionValuesChange = ({
  selectedCarrier,
  changedValues,
  changeType,
  values,
}: {
  selectedCarrier: Carrier;
  changedValues: any;
  changeType: string;
  values: any;
}) => {
  const { antdLocationQuestionData, updateUnderWritingQuestionsByKey } = useUnderWritingQuestionStore.getState();
  let allQuestions;
  const storeKey = UW_STORE_KEYS.LOCATION_QUESTION_DATA;
  const allCarrierQuestions = antdLocationQuestionData;
  const locationId = Object.keys(changedValues?.[changeType])[0];
  const questionKey = Object.keys(changedValues?.[changeType]?.[locationId]?.[selectedCarrier])[0];
  const questionValue = Object.values(changedValues?.[changeType]?.[locationId]?.[selectedCarrier])[0];
  const locationIndex = antdLocationQuestionData?.findIndex((location) => location.location.locationId === locationId);
  const storeIndex = [locationIndex, 'questions', selectedCarrier];
  if (typeof locationIndex === 'number' && locationIndex >= 0) {
    allQuestions = antdLocationQuestionData?.[locationIndex]?.['questions']?.[selectedCarrier] || [];
    if (questionKey && questionValue) {
      set(values, [changeType, locationId, selectedCarrier, questionKey], questionValue);
      updateUnderWritingQuestionsByKey(UW_STORE_KEYS.FORM_VALUES, values);
      updateUnderWritingQuestionsByKey(UW_STORE_KEYS.CURRENT_FIELD_NAME, [
        changeType,
        locationId,
        selectedCarrier,
        questionKey,
      ]);
    }
  }

  return {
    storeKey,
    allCarrierQuestions,
    questionKey,
    questionValue,
    storeIndex,
    allQuestions,
    prefixDataIndex: [UNDERWRITING_STEP_KEYS.LOCATIONS, locationId, selectedCarrier],
  };
};

export const onAdditionalInterestUWQuestionValuesChange = ({
  selectedCarrier,
  changedValues,
  changeType,
  values,
}: {
  selectedCarrier: Carrier;
  changedValues: any;
  changeType: string;
  values: any;
}) => {
  const { antdAdditionalInterestQuestionData, updateUnderWritingQuestionsByKey } =
    useUnderWritingQuestionStore.getState();
  let allQuestions;
  const storeKey = UW_STORE_KEYS.ADDITIONAL_INTEREST_QUESTION_DATA;
  const allCarrierQuestions = antdAdditionalInterestQuestionData;
  const additionalInterestId = Object.keys(changedValues?.[changeType])[0];
  const questionKey = Object.keys(changedValues?.[changeType]?.[additionalInterestId]?.[selectedCarrier])[0];
  const questionValue = Object.values(changedValues?.[changeType]?.[additionalInterestId]?.[selectedCarrier])[0];
  const additionalInterestIndex = antdAdditionalInterestQuestionData?.findIndex(
    (additionalInterest) => additionalInterest?.additionalInterest?.additionalInterestId === additionalInterestId,
  );
  const storeIndex = [additionalInterestIndex, 'questions', selectedCarrier];
  if (typeof additionalInterestIndex === 'number' && additionalInterestIndex >= 0) {
    allQuestions =
      antdAdditionalInterestQuestionData?.[additionalInterestIndex]?.['questions']?.[selectedCarrier] || [];
    if (questionKey && questionValue) {
      set(values, [changeType, additionalInterestId, selectedCarrier, questionKey], questionValue);
      updateUnderWritingQuestionsByKey(UW_STORE_KEYS.FORM_VALUES, values);
      updateUnderWritingQuestionsByKey(UW_STORE_KEYS.CURRENT_FIELD_NAME, [
        changeType,
        additionalInterestId,
        selectedCarrier,
        questionKey,
      ]);
    }
  }

  return {
    storeKey,
    allCarrierQuestions,
    questionKey,
    questionValue,
    storeIndex,
    allQuestions,
    prefixDataIndex: [UNDERWRITING_STEP_KEYS.ADDITIONAL_INTEREST, additionalInterestId, selectedCarrier],
  };
};

export const autoFillLocationDefaultValues = ({ locationId }: { locationId: string }) => {
  const { underWritingQuestionForm, antdLocationQuestionData } = useUnderWritingQuestionStore.getState();

  const currentUWStepCarrier = getCurrentUwStepCarrier();

  const antdLocationData = antdLocationQuestionData?.find(
    (locationData) => locationData.location.locationId === locationId,
  );
  antdLocationData &&
    currentUWStepCarrier &&
    antdLocationData?.questions?.[currentUWStepCarrier]?.forEach((question) => {
      if (!FALSY_VALUES.includes(question?.defaultAnswer)) {
        const answer = question?.options?.find(
          (ans) => ans?.label?.toLowerCase() === question?.defaultAnswer?.toLowerCase(),
        );
        if (answer) {
          underWritingQuestionForm?.setFields([
            {
              name: [UNDERWRITING_STEP_KEYS.LOCATIONS, locationId, currentUWStepCarrier, question.dataIndex],
              value: answer.value,
              errors: [],
            },
          ]);
        }
        // TODO: to add multi select condition once BE starts sending them
      }
    });
};

export const autoFillAdditionalInterestDefaultValues = ({ additionalInterestId }: { additionalInterestId: string }) => {
  const { underWritingQuestionForm, antdAdditionalInterestQuestionData } = useUnderWritingQuestionStore.getState();

  const currentUWStepCarrier = getCurrentUwStepCarrier();

  const antdAdditionalInterestData = antdAdditionalInterestQuestionData?.find(
    (additionalInterest) => additionalInterest.additionalInterest?.additionalInterestId === additionalInterestId,
  );
  antdAdditionalInterestData &&
    currentUWStepCarrier &&
    antdAdditionalInterestData?.questions?.[currentUWStepCarrier]?.forEach((question) => {
      if (!FALSY_VALUES.includes(question?.defaultAnswer)) {
        const answer = question?.options?.find(
          (ans) => ans?.label?.toLowerCase() === question?.defaultAnswer?.toLowerCase(),
        );
        if (answer) {
          underWritingQuestionForm?.setFields([
            {
              name: [
                UNDERWRITING_STEP_KEYS.ADDITIONAL_INTEREST,
                additionalInterestId,
                currentUWStepCarrier,
                question.dataIndex,
              ],
              value: answer.value,
              errors: [],
            },
          ]);
        }
        // TODO: to add multi select condition once BE starts sending them
      }
    });
};

export const autoFillGeneralDefaultValues = () => {
  const { underWritingQuestionForm, antdGeneralQuestionData } = useUnderWritingQuestionStore.getState();

  const currentUWStepCarrier = getCurrentUwStepCarrier();

  antdGeneralQuestionData &&
    currentUWStepCarrier &&
    antdGeneralQuestionData[currentUWStepCarrier]?.forEach((question) => {
      if (!FALSY_VALUES.includes(question?.defaultAnswer)) {
        const answer = question?.options?.find(
          (ans) => ans?.label?.toLowerCase() === question?.defaultAnswer?.toLowerCase(),
        );
        if (answer) {
          underWritingQuestionForm?.setFields([
            {
              name: [UNDERWRITING_STEP_KEYS.APPLICATION, currentUWStepCarrier, question.dataIndex],
              value: answer.value,
              errors: [],
            },
          ]);
        }
        // TODO: to add multi select condition once BE starts sending them
      }
    });
};
export const autoFillDefaultValues = (prefixDataIndex: string[]) => {
  const {
    antdGeneralQuestionData,
    updateUnderWritingQuestionsByKey,
    antdLocationQuestionData,
    antdAdditionalInterestQuestionData,
  } = useUnderWritingQuestionStore.getState();
  switch (prefixDataIndex[0]) {
    case UNDERWRITING_STEP_KEYS.APPLICATION:
      {
        autoFillGeneralDefaultValues();
        const carrier = prefixDataIndex[1];
        if (carrier && antdGeneralQuestionData) {
          antdGeneralQuestionData[carrier] = updateAllGeneralUwQuestion({
            questions: antdGeneralQuestionData[carrier],
            prefixDataIndex,
          });
          updateUnderWritingQuestionsByKey(UW_STORE_KEYS.GENERAL_QUESTION_DATA, antdGeneralQuestionData);
        }
      }
      break;
    case UNDERWRITING_STEP_KEYS.LOCATIONS: {
      const locationId = prefixDataIndex[1];
      const carrier = prefixDataIndex[2];
      locationId && autoFillLocationDefaultValues({ locationId });
      if (antdLocationQuestionData) {
        const index = antdLocationQuestionData?.findIndex((location) => location?.location?.locationId === locationId);
        if (locationId && antdLocationQuestionData && index !== -1) {
          antdLocationQuestionData[index]['questions'][carrier] = updateAllLocationUwQuestion({
            questions: antdLocationQuestionData[index]['questions'][carrier],
            locationId: antdLocationQuestionData[index]?.location?.locationId || '',
            prefixDataIndex,
          });
          updateUnderWritingQuestionsByKey(UW_STORE_KEYS.LOCATION_QUESTION_DATA, antdLocationQuestionData);
        }
      }
      break;
    }

    case UNDERWRITING_STEP_KEYS.ADDITIONAL_INTEREST: {
      const additionalInterestId = prefixDataIndex[1];
      const carrier = prefixDataIndex[2];
      additionalInterestId && autoFillAdditionalInterestDefaultValues({ additionalInterestId });
      if (antdAdditionalInterestQuestionData) {
        const index = antdAdditionalInterestQuestionData?.findIndex(
          (additionalInterest) => additionalInterest?.additionalInterest?.additionalInterestId === additionalInterestId,
        );
        if (additionalInterestId && antdAdditionalInterestQuestionData && index !== -1) {
          antdAdditionalInterestQuestionData[index]['questions'][carrier] = updateAllAdditionalInterestUwQuestion({
            questions: antdAdditionalInterestQuestionData[index]['questions'][carrier],
            additionalInterestId:
              antdAdditionalInterestQuestionData[index]?.additionalInterest?.additionalInterestId || '',
            prefixDataIndex,
          });
          updateUnderWritingQuestionsByKey(
            UW_STORE_KEYS.ADDITIONAL_INTEREST_QUESTION_DATA,
            antdAdditionalInterestQuestionData,
          );
        }
      }
      break;
    }

    default:
      break;
  }
};

export const updateAllGeneralUwQuestion = ({
  questions,
  prefixDataIndex,
  questionValues = [],
}: {
  questions: IApplicationQuestion[];
  prefixDataIndex: string[];
  questionValues?: IUnderwritingDetailsInfo[];
}) => {
  const { underWritingQuestionForm } = useUnderWritingQuestionStore.getState();
  let cloneQuestions = cloneDeep(questions);
  cloneQuestions.forEach((question) => {
    const questionValue = questionValues?.find((el) => el.questionId === question.dataIndex)?.answer;
    const updatedQuestions = updateUWQuestions({
      allQuestions: cloneQuestions,
      questionKey: question.dataIndex,
      questionValue,
      prefixDataIndex,
      form: underWritingQuestionForm,
    });
    cloneQuestions = updatedQuestions;
  });

  return cloneQuestions;
};

export const updateAllLocationUwQuestion = ({
  questions,
  locationId,
  prefixDataIndex,
  questionValues = [],
}: {
  questions: IApplicationQuestion[];
  locationId: string;
  prefixDataIndex: string[];
  questionValues?: ILocationInfo[];
}) => {
  const { underWritingQuestionForm } = useUnderWritingQuestionStore.getState();
  let cloneQuestions = cloneDeep(questions);
  if (locationId) {
    cloneQuestions.forEach((question) => {
      const locationIndex = questionValues?.findIndex((el) => el.locationId === locationId);
      const questionValue =
        locationIndex && locationIndex !== -1
          ? questionValues?.[locationIndex]?.locationUWInformation?.find((el) => el.questionId === question.dataIndex)
              ?.answer
          : undefined;
      const updatedQuestions = updateUWQuestions({
        allQuestions: cloneQuestions,
        questionKey: question.dataIndex,
        questionValue,
        prefixDataIndex,
        form: underWritingQuestionForm,
      });
      cloneQuestions = updatedQuestions;
    });
  }
  return cloneQuestions;
};

export const updateAllAdditionalInterestUwQuestion = ({
  questions,
  prefixDataIndex,
  additionalInterestId,
  questionValues = [],
}: {
  questions: IApplicationQuestion[];
  prefixDataIndex: string[];
  questionValues?: IAdditionalInterestInfo[];
  additionalInterestId: string;
}) => {
  const { underWritingQuestionForm } = useUnderWritingQuestionStore.getState();
  let cloneQuestions = cloneDeep(questions);
  if (additionalInterestId) {
    cloneQuestions.forEach((question) => {
      const additionalInterestIndex = questionValues?.findIndex(
        (el) => el.additionalInterestId === additionalInterestId,
      );
      const questionValue =
        additionalInterestIndex && additionalInterestIndex !== -1
          ? questionValues?.[additionalInterestIndex]?.additionalInterestUWInformation?.find(
              (el) => el.questionId === question.dataIndex,
            )?.answer
          : undefined;
      const updatedQuestions = updateUWQuestions({
        allQuestions: cloneQuestions,
        questionKey: question.dataIndex,
        questionValue,
        prefixDataIndex,
        form: underWritingQuestionForm,
      });
      cloneQuestions = updatedQuestions;
    });
  }
  return cloneQuestions;
};

const setNoToAllAnswer = ({
  questions,
  prefixDataIndex,
}: {
  questions: IApplicationQuestion[];
  prefixDataIndex: string[];
}) => {
  const { underWritingQuestionForm } = useUnderWritingQuestionStore.getState();
  questions?.forEach(
    (question) =>
      question.type === QuestionType.RADIO &&
      question.dataIndex &&
      underWritingQuestionForm?.setFields([
        {
          name: prefixDataIndex?.concat([question.dataIndex]),
          value: question.options?.find((option) => FALSY_LABEL.includes(option?.label?.toLowerCase().trim()))?.value,
          errors: [],
        },
      ]),
  );
};

export const autoFillAnswerNoToAll = ({ prefixDataIndex }: { prefixDataIndex: string[] }) => {
  const {
    updateUnderWritingQuestionsByKey,
    antdGeneralQuestionData,
    antdLocationQuestionData,
    antdAdditionalInterestQuestionData,
  } = useUnderWritingQuestionStore.getState();

  switch (prefixDataIndex[0]) {
    case UNDERWRITING_STEP_KEYS.APPLICATION: {
      const carrier = prefixDataIndex?.[1];

      if (carrier && antdGeneralQuestionData) {
        setNoToAllAnswer({ prefixDataIndex, questions: antdGeneralQuestionData[carrier] });
        antdGeneralQuestionData[carrier] = updateAllGeneralUwQuestion({
          questions: antdGeneralQuestionData[carrier],
          prefixDataIndex,
        });
        updateUnderWritingQuestionsByKey(UW_STORE_KEYS.GENERAL_QUESTION_DATA, antdGeneralQuestionData);
      }
      break;
    }
    case UNDERWRITING_STEP_KEYS.LOCATIONS: {
      const locationId = prefixDataIndex[1];
      const carrier = prefixDataIndex[2];
      if (antdLocationQuestionData) {
        const index = antdLocationQuestionData?.findIndex((location) => location?.location?.locationId === locationId);
        setNoToAllAnswer({
          prefixDataIndex,
          questions: antdLocationQuestionData?.[index]?.['questions']?.[carrier] || [],
        });
        if (locationId && antdLocationQuestionData && index !== -1) {
          antdLocationQuestionData[index]['questions'][carrier] = updateAllLocationUwQuestion({
            questions: antdLocationQuestionData[index]['questions'][carrier],
            locationId: antdLocationQuestionData[index]?.location?.locationId || '',
            prefixDataIndex,
          });
          updateUnderWritingQuestionsByKey(UW_STORE_KEYS.LOCATION_QUESTION_DATA, antdLocationQuestionData);
        }
      }

      break;
    }
    case UNDERWRITING_STEP_KEYS.ADDITIONAL_INTEREST: {
      const additionalInterestId = prefixDataIndex[1];
      const carrier = prefixDataIndex[2];
      if (antdAdditionalInterestQuestionData) {
        const index = antdAdditionalInterestQuestionData?.findIndex(
          (additionalInterest) => additionalInterest?.additionalInterest?.additionalInterestId === additionalInterestId,
        );
        setNoToAllAnswer({
          prefixDataIndex,
          questions: antdAdditionalInterestQuestionData?.[index]?.['questions']?.[carrier] || [],
        });
        if (additionalInterestId && antdAdditionalInterestQuestionData && typeof index === 'number' && index !== -1) {
          antdAdditionalInterestQuestionData[index]['questions'][carrier] = updateAllAdditionalInterestUwQuestion({
            questions: antdAdditionalInterestQuestionData[index]['questions'][carrier],
            additionalInterestId:
              antdAdditionalInterestQuestionData[index]?.additionalInterest?.additionalInterestId || '',
            prefixDataIndex,
          });
          updateUnderWritingQuestionsByKey(
            UW_STORE_KEYS.ADDITIONAL_INTEREST_QUESTION_DATA,
            antdAdditionalInterestQuestionData,
          );
        }
      }
      break;
    }

    default:
      break;
  }
};

const updateUwQuestionForGeneralQuestionWhileAutoFill = ({ carrier }: { carrier: Carrier }) => {
  const { applicationData } = useApplicationStore.getState();
  const {
    antdGeneralQuestionData: storeAntdGeneralQuestionData,
    updateUnderWritingQuestionsByKey,
    underWritingQuestionForm,
  } = useUnderWritingQuestionStore.getState();
  const antdGeneralQuestionData = cloneDeep(storeAntdGeneralQuestionData);
  if (antdGeneralQuestionData && carrier) {
    antdGeneralQuestionData?.[carrier]?.forEach((question) => {
      const questionValue = applicationData?.underwritingDetails?.find(
        (el) => el.questionId === question.dataIndex,
      )?.answer;
      const updatedQuestions = updateUWQuestions({
        allQuestions: antdGeneralQuestionData?.[carrier],
        questionKey: question.dataIndex,
        questionValue,
        prefixDataIndex: [UNDERWRITING_STEP_KEYS.APPLICATION, carrier],
        form: underWritingQuestionForm,
      });
      antdGeneralQuestionData[carrier] = updatedQuestions;
    });
    updateUnderWritingQuestionsByKey(UW_STORE_KEYS.GENERAL_QUESTION_DATA, antdGeneralQuestionData);
  }
};

const updateUwQuestionForLocationWhileAutoFill = ({ carrier }: { carrier: Carrier }) => {
  const { applicationData } = useApplicationStore.getState();
  const {
    antdLocationQuestionData: storeAntdLocationQuestionData,
    updateUnderWritingQuestionsByKey,
    underWritingQuestionForm,
  } = useUnderWritingQuestionStore.getState();
  const antdLocationQuestionData = cloneDeep(storeAntdLocationQuestionData);
  // updating the childToParentQuestionsMap for each parent question
  if (antdLocationQuestionData && carrier) {
    antdLocationQuestionData?.forEach((location, index) => {
      const questionData = location.questions?.[carrier];
      if (questionData && questionData.length > 0) {
        questionData.forEach((question) => {
          const locationUWInformation = applicationData?.locationDetails?.find(
            (el) => el.locationId === location.location.locationId,
          )?.locationUWInformation;
          const questionValue = locationUWInformation?.find((el) => el.questionId === question.dataIndex)?.answer;
          const updatedQuestions = updateUWQuestions({
            allQuestions: questionData,
            questionKey: question.dataIndex,
            questionValue,
            prefixDataIndex: [UNDERWRITING_STEP_KEYS.LOCATIONS, location.location.locationId || '', carrier],
            form: underWritingQuestionForm,
          });
          antdLocationQuestionData[index]['questions'][carrier] = updatedQuestions;
        });
      }
    });
    updateUnderWritingQuestionsByKey(UW_STORE_KEYS.LOCATION_QUESTION_DATA, antdLocationQuestionData);
  }
};

const updateUwQuestionForAdditionalInterestWhileAutoFill = ({ carrier }: { carrier: Carrier }) => {
  const { applicationData } = useApplicationStore.getState();
  const {
    antdAdditionalInterestQuestionData: storeAntdAdditionalInterestQuestionData,
    updateUnderWritingQuestionsByKey,
    underWritingQuestionForm,
  } = useUnderWritingQuestionStore.getState();
  const antdAdditionalInterestQuestionData = cloneDeep(storeAntdAdditionalInterestQuestionData);
  // updating the childToParentQuestionsMap for each parent question
  if (antdAdditionalInterestQuestionData && carrier) {
    antdAdditionalInterestQuestionData?.forEach((additionalInterest, index) => {
      const questionData = additionalInterest.questions?.[carrier];
      if (questionData && questionData.length > 0) {
        questionData.forEach((question) => {
          const additionalInterestUWInformation = applicationData?.additionalInterestDetails?.find(
            (el) => el.additionalInterestId === additionalInterest?.additionalInterest?.additionalInterestId,
          )?.additionalInterestUWInformation;
          const questionValue = additionalInterestUWInformation?.find(
            (el) => el.questionId === question.dataIndex,
          )?.answer;
          const updatedQuestions = updateUWQuestions({
            allQuestions: questionData,
            questionKey: question.dataIndex,
            questionValue,
            prefixDataIndex: [
              UNDERWRITING_STEP_KEYS.ADDITIONAL_INTEREST,
              additionalInterest?.additionalInterest?.additionalInterestId || '',
              carrier,
            ],
            form: underWritingQuestionForm,
          });
          antdAdditionalInterestQuestionData[index]['questions'][carrier] = updatedQuestions;
        });
      }
    });
    updateUnderWritingQuestionsByKey(
      UW_STORE_KEYS.ADDITIONAL_INTEREST_QUESTION_DATA,
      antdAdditionalInterestQuestionData,
    );
  }
};

export const updateUwQuestionWhileAutofill = ({ carrier }: { carrier: Carrier }) => {
  updateUwQuestionForGeneralQuestionWhileAutoFill({ carrier });
  updateUwQuestionForLocationWhileAutoFill({ carrier });
  updateUwQuestionForAdditionalInterestWhileAutoFill({ carrier });
};

export const getQuestionInfo = (questionId: string, questions: IApplicationQuestion[]) => {
  const question = questions?.find((q) => q.dataIndex === questionId);
  return {
    isAddressType: question?.type?.toLowerCase() === ANTD_QUESTION_TYPE.ADDRESS,
    hasRequiredRule: question?.rules?.find((rule: any) => rule?.required),
    question,
  };
};

const validateGeneralUwQuestion = ({
  answers,
  prefix,
  questions,
}: {
  answers: Object;
  prefix: string[];
  questions?: IApplicationQuestion[];
}) => {
  const { underWritingQuestionForm } = useUnderWritingQuestionStore.getState();
  const { setShowLocationError } = useLocationInputStore.getState();
  let isAnswerValid = true;
  if (
    !questions?.length ||
    !(
      [
        UNDERWRITING_STEP_KEYS.APPLICATION,
        UNDERWRITING_STEP_KEYS.LOCATIONS,
        UNDERWRITING_STEP_KEYS.ADDITIONAL_INTEREST,
      ] as string[]
    ).includes(prefix[0])
  ) {
    return true;
  }

  map(answers, (value: any, key) => {
    const questionInfo = getQuestionInfo(key, questions);
    if (questionInfo.isAddressType) {
      if (questionInfo.hasRequiredRule && !validateAddress(value as Address)) {
        isAnswerValid = false;
        setShowLocationError(true, [...prefix, key].join('_'));

        // this is for autoscroll to error
        underWritingQuestionForm?.setFields([
          {
            name: [...prefix, key],
            errors: [''],
          },
        ]);
      } else if (isObject(value) && !isAddressEmpty(value as Address) && !validateAddress(value as Address)) {
        isAnswerValid = false;
        setShowLocationError(true, [...prefix, key].join('_'));
        // this is for autoscroll to error
        underWritingQuestionForm?.setFields([
          {
            name: [...prefix, key],
            errors: [''],
          },
        ]);
      } else {
        underWritingQuestionForm?.setFields([
          {
            name: [...prefix, key],
            errors: [],
          },
        ]);
      }
    }
  });
  return isAnswerValid;
};

export const validateUnderWritingQuestion = async () => {
  const {
    underWritingQuestionForm: form,
    antdLocationQuestionData,
    antdGeneralQuestionData,
    antdAdditionalInterestQuestionData,
  } = useUnderWritingQuestionStore.getState();
  const underwritingAnswer = {
    ...form?.getFieldsValue(),
  };
  const currentCarrier = getCurrentUwStepCarrier();

  let isUwAnswersValid = true;

  const applicationAnswer = get(underwritingAnswer, [UNDERWRITING_STEP_KEYS.APPLICATION, currentCarrier], {});

  isUwAnswersValid = validateGeneralUwQuestion({
    answers: applicationAnswer,
    prefix: [UNDERWRITING_STEP_KEYS.APPLICATION, currentCarrier],
    questions: get(antdGeneralQuestionData, [currentCarrier], []),
  });

  underwritingAnswer?.locations &&
    Object.keys(underwritingAnswer.locations).forEach((locationId) => {
      const index = antdLocationQuestionData?.findIndex((locDetail) => locDetail?.location?.locationId === locationId);
      const locationDetailIndex = typeof index === 'number' ? index : -1;
      if (locationDetailIndex === -1) {
        return;
      }
      const locationAnswer = get(
        underwritingAnswer,
        [UNDERWRITING_STEP_KEYS.LOCATIONS, locationId, currentCarrier],
        {},
      );
      const isLocationAnswerValid = antdLocationQuestionData?.[locationDetailIndex]?.location?.locationId
        ? validateGeneralUwQuestion({
            answers: locationAnswer,
            prefix: [UNDERWRITING_STEP_KEYS.LOCATIONS, locationId, currentCarrier],
            questions: get(antdLocationQuestionData, [locationDetailIndex, 'questions', currentCarrier], []),
          })
        : true;

      isUwAnswersValid = isUwAnswersValid && isLocationAnswerValid;
    });

  underwritingAnswer?.additionalInterest &&
    Object.keys(underwritingAnswer.additionalInterest).forEach((additionalInterestId) => {
      const index = antdAdditionalInterestQuestionData?.findIndex(
        (additionalInterestDetails) =>
          additionalInterestDetails?.additionalInterest?.additionalInterestId === additionalInterestId,
      );
      const additionalInterestIndex = typeof index === 'number' ? index : -1;
      if (additionalInterestIndex === -1) {
        return;
      }
      const additionalInterestAnswer = get(
        underwritingAnswer,
        [UNDERWRITING_STEP_KEYS.ADDITIONAL_INTEREST, additionalInterestId, currentCarrier],
        {},
      );
      const isAdditionalInterestAnswerValid = antdAdditionalInterestQuestionData?.[additionalInterestIndex]
        ?.additionalInterest?.additionalInterestId
        ? validateGeneralUwQuestion({
            answers: additionalInterestAnswer,
            prefix: [UNDERWRITING_STEP_KEYS.ADDITIONAL_INTEREST, additionalInterestId, currentCarrier],
            questions: get(
              antdAdditionalInterestQuestionData,
              [additionalInterestIndex, 'questions', currentCarrier],
              [],
            ),
          })
        : true;

      isUwAnswersValid = isUwAnswersValid && isAdditionalInterestAnswerValid;
    });

  await form?.validateFields();

  return isUwAnswersValid;
};
