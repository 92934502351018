import { useCallback, useMemo } from 'react';

import LocationInput from '../../../components/question/locationInputV2/locationInput';
import { ICFLocationV2 } from './interface';

/** This component acts as wrapper to all the locationInputV2 and for any use case. Cuz eventually locationInputV2 requires an onSave from parent. */
// When you want to form answer in multilevel object format then you can pass id in "_" separated, else you can directly pass in string format(It will store answer in key value format).
// dataStoreKey:- when we want to store answer in custom path or format then we can pass dataStoreKey, it will store answer in that path.
const LocationV2 = ({ form, id: dataIndex, disabled = false, dataStoreKey = [] }: ICFLocationV2) => {
  const dataIndexKey = useMemo(
    () => (dataStoreKey?.length > 0 ? dataStoreKey : dataIndex?.split('_')),
    [dataIndex, dataStoreKey],
  );

  const onAddressSave = useCallback(
    (currentAddress: any) => {
      form?.setFieldValue(dataIndexKey, currentAddress);
    },
    [dataIndexKey, form],
  );

  return (
    <LocationInput
      disabled={disabled}
      id={dataIndex}
      value={form?.getFieldValue(dataIndexKey)}
      onSave={onAddressSave}
    />
  );
};

export default LocationV2;
