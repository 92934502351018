import {
  IBindQuoteInput,
  IGetPaymentOptionsInput,
  IGetQuotePaymentDetailsInput,
  IMakePaymentTransactionInput,
  ISelectPaymentPlanInput,
} from '@coverforce-platform/cf-common-api-model';
import { PaymentPlanType } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { APPLICATION_STORE_KEYS } from '../../constants/applicationV2Constants';
import { getPolicyDetails } from '../../externalServices/V2/applicationV2';
import {
  bindApplication as bindApplicationRequest,
  bindApplicationV2,
  getPaymentDetails as getPaymentDetailsRequest,
  getPaymentOptions as getPaymentOptionsRequest,
  getPaymentPlans as getPaymentPlansRequest,
  makePayment as makePaymentRequest,
  pollForApplication,
  selectPaymentPlan as selectPaymentPlanRequest,
} from '../../externalServices/V2/payment';
import { useApplicationStore } from '../../pages/v2/application/store';
import { errorV2 } from '../../ui-core/Notification';
import { uniq } from '../../utils/lodash';
import { PAYMENT_ERROR_MESSAGE, PAYMENT_STORE_META_DATA } from './constants';
import { IPaymentStore } from './interface';

export const usePaymentStore = create<IPaymentStore>()(
  devtools(
    (set, get) => ({
      paymentPlanTypes: [],
      paymentPlans: [],
      paymentOption: undefined,
      isPaymentPlanLoading: false,
      isBounding: false,
      policyDetails: undefined,
      paymentForm: undefined,

      // payment integration v2
      isPaymentPlanConfirmed: false,
      isPaymentPlanConfirmedLoading: false,
      paymentOptionsData: undefined,
      makePaymentData: undefined,
      paymentDetailsData: undefined,
      isPaymentDetailsLoading: false,

      getPaymentOptions: async (params: IGetPaymentOptionsInput) => {
        const response = await getPaymentOptionsRequest(params);
        return response;
      },

      makePayment: async (param: IMakePaymentTransactionInput) => {
        const response = await makePaymentRequest(param);
        return response;
      },

      getPaymentDetails: async (params: IGetQuotePaymentDetailsInput) => {
        const response = await getPaymentDetailsRequest(params);
        return response;
      },
      // payment integration v2 ends

      setIsBounding: (state: boolean) => {
        set({ isBounding: state }, false, PAYMENT_STORE_META_DATA.SET_IS_BOUNDING);
      },

      setPaymentOption: (paymentOption) => {
        set({ paymentOption }, false, PAYMENT_STORE_META_DATA.SET_PAYMENT_OPTION);
      },

      setPaymentPlan: async (paymentInfo: ISelectPaymentPlanInput) => {
        const response = await selectPaymentPlanRequest(paymentInfo);
        return response;
      },

      fetchPaymentPlans: async (applicationId: string, quoteId: string, isPaymentPlanLoading = true) => {
        let paymentTypes: PaymentPlanType[] = [];
        try {
          set({ isPaymentPlanLoading }, false, PAYMENT_STORE_META_DATA.SET_PAYMENT_PLAN_FETCHING);
          const paymentPlansDetails = await getPaymentPlansRequest({
            applicationId,
            cfQuoteId: quoteId,
          });

          paymentTypes = paymentPlansDetails?.paymentPlans?.map((item) => item?.paymentPlanType) || [];
          set(
            {
              paymentPlanTypes: [...uniq(paymentTypes)],
              paymentPlans: paymentPlansDetails?.paymentPlans,
            },
            false,
            PAYMENT_STORE_META_DATA.SET_PAYMENT_PLANS,
          );
        } catch (err: any) {
          errorV2(err?.[0]?.errorMessage || PAYMENT_ERROR_MESSAGE.FETCH_PAYMENT_PLANS);
        } finally {
          set({ isPaymentPlanLoading: false }, false, PAYMENT_STORE_META_DATA.SET_PAYMENT_PLAN_FETCHING);
        }
      },

      bindPolicy: async (bindPolicyInfo: IBindQuoteInput) => {
        await bindApplicationRequest({
          applicationId: bindPolicyInfo?.applicationId,
          cfQuoteId: bindPolicyInfo?.cfQuoteId,
        });
      },

      bindPolicyV2: async (bindPolicyInfo: IBindQuoteInput) => {
        return await bindApplicationV2({
          applicationId: bindPolicyInfo?.applicationId,
          cfQuoteId: bindPolicyInfo?.cfQuoteId,
        });
      },

      fetchPolicy: async (bindQuoteInfo) => {
        const policyDetails = await getPolicyDetails(bindQuoteInfo);
        set({ policyDetails }, false, PAYMENT_STORE_META_DATA.SET_POLICY_DETAILS);
        return policyDetails;
      },

      pollApplication: async (applicationId) => {
        const { clearPaymentStore } = get();
        const { updateApplicationByKey } = useApplicationStore.getState();
        const applicationData = await pollForApplication(applicationId);
        // In case user creates new application while on application page with existing application in BIND_IN_PROGRESS
        // we need to not let it update any data and also clear the payment store so as to not interfere with newly created application
        if (!window.location.pathname.includes(applicationId)) {
          clearPaymentStore();
          return;
        }

        updateApplicationByKey(APPLICATION_STORE_KEYS.APPLICATION_DATA, applicationData);
        return applicationData;
      },

      updatePaymentsByKey: async (storeKey: keyof IPaymentStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, PAYMENT_STORE_META_DATA.UPDATE_PAYMENT_STORE_BY_KEY),

      clearPaymentStore: () => {
        set(
          {
            isPaymentPlanLoading: false,
            paymentPlanTypes: [],
            paymentPlans: [],
            paymentOption: undefined,
            isBounding: false,
            policyDetails: undefined,
            paymentForm: undefined,
            // payment integration v2
            isPaymentPlanConfirmed: false,
            isPaymentPlanConfirmedLoading: false,
            paymentOptionsData: undefined,
            makePaymentData: undefined,
            paymentDetailsData: undefined,
            isPaymentDetailsLoading: false,
            // payment integration v2 ends
          },
          false,
          PAYMENT_STORE_META_DATA.CLEAR_PAYMENT_STORE,
        );
      },
    }),
    { anonymousActionType: PAYMENT_STORE_META_DATA.ANONYMOUS_ACTION_NAME, name: PAYMENT_STORE_META_DATA.STORE_NAME },
  ),
);
