import { AdditionalInterestType } from '@coverforce-platform/cf-common-types';

export const ADDITIONAL_INTEREST_TYPE_DISPLAY_TEXT: { [key in AdditionalInterestType]: string } = {
  [AdditionalInterestType.FRANCHISOR]: 'Grantor of Franchise',
  [AdditionalInterestType.VENDOR]: 'Vendor',
  [AdditionalInterestType.DESIGNATED_PERSON_OR_ORGANISATION]: 'Designated Person or Organization',
  [AdditionalInterestType.OWNERS_LESSEES_CONTRACTORS]: 'Owners, Lessees or Contractors',
  [AdditionalInterestType.LAND_LEASED_INTERESTS]: 'Owners Or Other Interests From Whom Land Has Been Leased',
  [AdditionalInterestType.MORTAGEE_ASSIGNEE_RECEIVER]: 'Mortgagee, Assignee or Receiver',
  [AdditionalInterestType.MANAGERS_LESSORS_OF_PREMISES]: 'Managers or Lessors of Premises',
  [AdditionalInterestType.LESSOR_LEASED_EQUIPMENT]: 'Lessor of Leased Equipment',
  [AdditionalInterestType.EXECUTORS_ADMINISTRATORS_TRUSTEES_BENEFICIARIES]:
    'Executors, Administrators, Trustees or Beneficiaries',
  [AdditionalInterestType.LICENSES_GRANTOR]: 'Grantor of Licenses',
  [AdditionalInterestType.OWNERS_LESSEES_CONTRACTORS_WRITTEN_AGREEMENT]:
    'Owners, Lessees or Contractors with Additional Insured Requirement in Construction Contract',
  [AdditionalInterestType.WAIVER_OF_SUBROGATION]: 'Waiver of Subrogation',
  [AdditionalInterestType.PRIMARY_AND_NON_CONTRIBUTORY]: 'Primary and Non-contributory',
};
