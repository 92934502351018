import { IDeleteApplicationInput } from '@coverforce-platform/cf-common-api-model';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getAccountByAccountId } from '../../../externalServices/V2/accountsV2';
import { deleteApplication, getApplicationsByAccountId } from '../../../externalServices/V2/applicationV2';
import { getAllPolicyTypes } from '../../../externalServices/V2/carrierRankingV2';
import { PAGE_SIZE } from '../../../globalConstants';
import { IAccountData, IAccountStore, IApplication } from './interface';

export const useAccountStore = create<IAccountStore>()(
  devtools(
    (set, get) => ({
      accountLoading: false,
      account: undefined,
      accountError: null,
      applicationLoading: false,
      applications: undefined,
      applicationError: null,
      policyLoading: false,
      policyTypes: undefined,
      policyError: null,

      fetchAccount: async (accountId: string) => {
        set({ accountLoading: true }, false, 'fetchAccountLoadingStart');
        try {
          const response = await getAccountByAccountId({
            accountId,
          });
          set({ account: response, accountError: null }, false, 'fetchAccount');
          return response;
        } catch (err: any) {
          set({ account: undefined, accountError: err }, false, 'fetchAccountError');
        } finally {
          set({ accountLoading: false }, false, 'fetchAccountLoadingStop');
        }
      },

      fetchApplications: async ({ accountId, size = PAGE_SIZE, loading = true }) => {
        set({ applicationLoading: loading }, false, 'fetchApplicationLoadingStart');
        try {
          let applications: IApplication[] = [];
          let pageNumber = 1;
          // eslint-disable-next-line no-constant-condition
          while (true) {
            const response = await getApplicationsByAccountId({
              page: pageNumber.toString(),
              size: size.toString(),
              accountId,
            });
            applications = [...applications, ...response.applications];
            if (!response.pagination?.nextPage) {
              break;
            }
            pageNumber++;
          }
          set({ applications, applicationError: null }, false, 'fetchApplication');
        } catch (err: any) {
          set({ applications: undefined, applicationError: err }, false, 'fetchApplicationError');
        } finally {
          set({ applicationLoading: false }, false, 'fetchApplicationLoadingStop');
        }
      },

      getPolicyTypes: async () => {
        set({ policyLoading: false }, false, 'fetchPolicyLoadingStart');
        try {
          const response = await getAllPolicyTypes();
          const policyTypes: { [k: string]: string } = {};
          response.policyTypes.forEach((policyType) => {
            policyTypes[policyType.policyTypeId] = policyType.policyTypeDisplayName;
          });
          set({ policyTypes, policyError: null }, false, 'fetchPolicy');
        } catch (err: any) {
          set({ policyTypes: undefined, policyError: err }, false, 'fetchPolicyError');
        } finally {
          set({ policyLoading: false }, false, 'fetchPolicyLoadingStop');
        }
      },

      clearAccount: () => set({ account: undefined, accountError: null, accountLoading: false }, false, 'clearAccount'),

      clearApplications: () =>
        set({ applications: undefined, applicationError: null, applicationLoading: false }, false, 'clearApplications'),

      clearPolicyTypes: () =>
        set({ policyTypes: undefined, policyError: null, policyLoading: false }, false, 'clearPolicyTypes'),

      updateAccountByKey: (storeKey: keyof IAccountData, value: any) =>
        set(() => ({ [storeKey]: value }), false, 'updateAccountByKey'),

      deleteApplication: async (params: IDeleteApplicationInput) => {
        await deleteApplication(params);
        const accountId = get().account?.accountId;
        accountId && get().fetchApplications({ accountId });
      },
    }),

    { anonymousActionType: 'account' },
  ),
);
