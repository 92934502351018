import { IAdditionalInterestInfo } from '@coverforce-platform/cf-common-api-model';

import { useApplicationStore } from '../../pages/v2/application/store';
import { ADDITIONAL_INTEREST_KEYS_MAPPING, ADDITIONAL_INTEREST_QUESTION_KEYS } from './constants';
import { IAdditionalInterestError } from './interface';

const getAdditionalInterestErrorObj = ({
  validatedDetails,
  index,
  additionalInterestKey,
}: {
  validatedDetails: { errors: IAdditionalInterestError[]; isValid: boolean };
  index: number;
  additionalInterestKey: string;
}) => {
  validatedDetails.errors[index] = {
    isError: true,
    errorMessage: `Please enter ${ADDITIONAL_INTEREST_KEYS_MAPPING[additionalInterestKey]}`,
  };
  const obj = {
    ...validatedDetails,
    isValid: false,
  };
  return obj;
};

export const validateAdditionalInterestDetails = (additionalInterestList: IAdditionalInterestInfo[]) => {
  let validatedDetails: { errors: IAdditionalInterestError[]; isValid: boolean } = {
    isValid: true,
    errors: [],
  };

  additionalInterestList?.forEach((additionalInterest, index) => {
    Object.keys(additionalInterest || {}).every((additionalInterestKey) => {
      const value = additionalInterest[additionalInterestKey as keyof IAdditionalInterestInfo];
      switch (additionalInterestKey) {
        case ADDITIONAL_INTEREST_QUESTION_KEYS.NAME:
        case ADDITIONAL_INTEREST_QUESTION_KEYS.TYPE: {
          if (!value) {
            validatedDetails = getAdditionalInterestErrorObj({ validatedDetails, additionalInterestKey, index });
            return false;
          }
          return true;
        }
        default:
          return true;
      }
    });
  });
  return validatedDetails;
};

export const createAdditionalInterestApiPayload = (
  additionalInterestList: IAdditionalInterestInfo[],
): IAdditionalInterestInfo[] => {
  const { applicationData } = useApplicationStore.getState();
  const { additionalInterestDetails = [] } = applicationData || {};
  const additionalInterestPayload: IAdditionalInterestInfo[] = additionalInterestList.map((item) => {
    const modifiedItem = additionalInterestDetails.find(
      (existingItem) =>
        existingItem.additionalInterestId === item.additionalInterestId &&
        existingItem.additionalInterestType !== item.additionalInterestType,
    );
    // Resetting UWInformation for updated items whose additionalInterestType has changed
    return modifiedItem ? { ...item, additionalInterestUWInformation: [] } : item;
  });
  return additionalInterestPayload;
};
