import { USState } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getJobCodesByState } from '../../externalServices/V2/location';
import { errorV2 } from '../../ui-core/Notification';
import { formatJobCodesToAntdOptions } from '../../utils/formatToAntdOptions';
import {
  OWNER_OFFICER_DETAILS_MODAL_MESSAGE,
  OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS,
  OWNER_OFFICER_DETAILS_MODAL_STORE_META_DATA,
} from './constants';
import { IOwnerDetailsModalStore } from './interface';
import { autoFillOwnerOfficerDetailsModalUtil } from './utils';

export const useOwnerOfficerDetailsModalStore = create<IOwnerDetailsModalStore>()(
  devtools(
    (set, get) => ({
      ownerOfficerClassCodes: [],
      ownersOfficersTitleOptions: {},
      ownerOfficerDetailsModalForm: undefined,
      questionElements: [],
      editedOwnerOfficerDetails: undefined,
      ownerOfficerModalLoading: false,

      updateOwnerOfficerDetailsModalStoreByKey: async (storeKey: keyof IOwnerDetailsModalStore, value: any) => {
        set(
          () => ({ [storeKey]: value }),
          false,
          OWNER_OFFICER_DETAILS_MODAL_STORE_META_DATA.UPDATE_OWNER_OFFICER_MODAL_STORE_BY_KEY,
        );
      },

      fetchOwnerOfficerClassCodes: async (state: USState, ownerOfficerModalLoading = true) => {
        const { updateOwnerOfficerDetailsModalStoreByKey } = get();
        updateOwnerOfficerDetailsModalStoreByKey(
          OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS.OWNER_OFFICER_DETAILS_MODAL_LOADING,
          ownerOfficerModalLoading,
        );

        try {
          const jobCodesByStateResponse = await getJobCodesByState({ state });
          const { jobCodesByState } = jobCodesByStateResponse || {};
          const ownerOfficerClassCodes = formatJobCodesToAntdOptions(jobCodesByState);
          set({ ownerOfficerClassCodes }, false, OWNER_OFFICER_DETAILS_MODAL_STORE_META_DATA.SET_CLASS_CODES);
          return ownerOfficerClassCodes;
        } catch (error: any) {
          errorV2(error?.[0]?.errorMessage || OWNER_OFFICER_DETAILS_MODAL_MESSAGE.FAILED_TO_FETCH_STATE_CLASS_CODES);
          return [];
        } finally {
          updateOwnerOfficerDetailsModalStoreByKey(
            OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS.OWNER_OFFICER_DETAILS_MODAL_LOADING,
            false,
          );
        }
      },

      autoFillOwnerOfficerDetailsModal: async () => {
        try {
          await autoFillOwnerOfficerDetailsModalUtil();
        } catch (error: any) {
          errorV2(error?.[0]?.errorMessage || OWNER_OFFICER_DETAILS_MODAL_MESSAGE.FAILED_TO_POPULATE_DATA);
        }
      },

      validateOwnerOfficerDetails: async () => {
        const { ownerOfficerDetailsModalForm } = get();
        try {
          await ownerOfficerDetailsModalForm?.validateFields();
          return true;
        } catch (error) {
          return false;
        }
      },

      clearOwnerOfficerDetailsModal: () => {
        const { ownerOfficerDetailsModalForm } = get();
        ownerOfficerDetailsModalForm?.resetFields();
        set(
          {
            questionElements: [],
            ownerOfficerClassCodes: [],
            ownerOfficerDetailsModalForm: undefined,
            editedOwnerOfficerDetails: undefined,
            ownerOfficerModalLoading: false,
          },
          false,
          OWNER_OFFICER_DETAILS_MODAL_STORE_META_DATA.CLEAR_OWNER_OFFICER_MODAL_STORE,
        );
      },
    }),

    {
      anonymousActionType: 'OWNER_OFFICER_MODAL_ANONYMOUS_ACTION',
      name: 'Owner Officer Modal Store',
    },
  ),
);
