import { AdditionalInterestType } from '@coverforce-platform/cf-common-types';

import { ADDITIONAL_INTEREST_TYPE_DISPLAY_TEXT } from '../../constants/additionalInterest';

export const getAdditionalInterestOptions = () => {
  return (Object.values(AdditionalInterestType) || [])
    .filter((item) => ADDITIONAL_INTEREST_TYPE_DISPLAY_TEXT[item])
    .map((item) => {
      return { label: ADDITIONAL_INTEREST_TYPE_DISPLAY_TEXT[item], value: item };
    });
};
