import {
  IApplicationInfo,
  ICarrierInfo,
  ILocationInfo,
  IUnderwritingDetailsInfo,
  IUpdateApplicationInput,
} from '@coverforce-platform/cf-common-api-model';
import {
  ApplicationFieldPathEnum,
  ApplicationFieldRequirementLevel,
  ApplicationStatus,
  Carrier,
  PolicyType,
} from '@coverforce-platform/cf-common-types';
import { NamePath } from 'antd/lib/form/interface';

import { useAdditionalInterestStore } from '../../../components/additionalInterest/store';
import { APPLICATION_QUESTION_INTERFACE_KEYS } from '../../../components/applicationQuestion/constants';
import { IApplicationQuestion } from '../../../components/applicationQuestion/interface';
import { useBrokerCodesStore } from '../../../components/brokerCodes/store';
import { useEffectiveDateModalStore } from '../../../components/effectiveDateModalV2/store';
import { useExperienceModifiersStore } from '../../../components/experienceModifiers/store';
import { useGeneralQuestionsStore } from '../../../components/generalQuestions/store';
import { useIndustrySelectionStore } from '../../../components/industrySelection/store';
import { useLiabilityLimitsStore } from '../../../components/liabilityLimits/store';
import { useLocationDetailsStore } from '../../../components/locationDetails/store';
import { useDeleteCarrierStore } from '../../../components/modals/deleteCarrier/store';
import { useModifiersStore } from '../../../components/modals/modifiers/store';
import { OWNER_OFFICER_FIELDS_PATH } from '../../../components/ownerOfficerDetails/constants';
import { useOwnerOfficerDetailsStore } from '../../../components/ownerOfficerDetails/store';
import { PAST_POLICY_LOSS_FIELDS_PATH } from '../../../components/pastPolicyLoss/constants';
import { usePastPolicyLossStore } from '../../../components/pastPolicyLoss/store';
import { useUnderWritingQuestionStore } from '../../../components/underwriting/store';
import { APPLICATION_TABS, UPDATE_APPLICATION_STEPS } from '../../../constants/applicationV2Constants';
import { HTTPS_CODES } from '../../../globalConstants';
import { removeUndefinedProperty } from '../../../helpers/Utils';
import { cloneDeep, differenceWith, isEmpty, isEqual, uniqBy } from '../../../utils/lodash';
import { useApplicationStore } from './store';

const createUpdateApplicationPayload = (
  payload: IApplicationInfo | IUpdateApplicationInput,
): IUpdateApplicationInput => {
  /** Only the required fields are created to send in update application. */
  return {
    applicationId: payload?.applicationId,
    applicationDetails: payload?.applicationDetails,
    policyDetails: payload?.policyDetails,
    basicBusinessDetails: payload?.basicBusinessDetails,
    companyStructure: payload?.companyStructure,
    pastPolicyLossDetails: payload?.pastPolicyLossDetails,
    additionalInterestDetails: payload?.additionalInterestDetails,
    underwritingDetails: payload?.underwritingDetails,
    statementDetails: payload?.statementDetails,
    brokerConfiguration: payload?.brokerConfiguration,
    modifiers: payload?.modifiers,
  };
};

export const getUpdateApplicationPayload = (updateApplicationStep: string): IUpdateApplicationInput => {
  const { applicationData } = useApplicationStore.getState();
  const updateApplicationPayload: IApplicationInfo = cloneDeep(applicationData!);

  switch (updateApplicationStep) {
    case UPDATE_APPLICATION_STEPS.GENERAL_QUESTIONS: {
      const { getGeneralQuestionsApiPayload } = useGeneralQuestionsStore.getState();
      const generalQuestionPayload = createUpdateApplicationPayload(getGeneralQuestionsApiPayload());
      return generalQuestionPayload;
    }

    case UPDATE_APPLICATION_STEPS.INDUSTRY_SELECTION: {
      const { getIndustrySelectionPayload } = useIndustrySelectionStore.getState();
      const industrySelectionPayload = createUpdateApplicationPayload(getIndustrySelectionPayload());
      return industrySelectionPayload;
    }

    case UPDATE_APPLICATION_STEPS.LOCATIONS: {
      const { getLocationApplicationApiPayload } = useLocationDetailsStore.getState();
      const locationApplicationPayload = createUpdateApplicationPayload(getLocationApplicationApiPayload());
      return locationApplicationPayload;
    }

    case UPDATE_APPLICATION_STEPS.EXPMODS: {
      const { getExpModsPayload } = useExperienceModifiersStore.getState();
      const expModsPayload = createUpdateApplicationPayload(getExpModsPayload());
      return expModsPayload;
    }

    case UPDATE_APPLICATION_STEPS.PAST_POLICYLOSS: {
      const { getPastPolicyLossApiPayload } = usePastPolicyLossStore.getState();
      const policyLossPayload = createUpdateApplicationPayload(getPastPolicyLossApiPayload());
      return policyLossPayload;
    }

    case UPDATE_APPLICATION_STEPS.OWNEROFFICER: {
      const { getOwnerOfficerDetailsApiPayload } = useOwnerOfficerDetailsStore.getState();
      const ownerOfficerPayload = createUpdateApplicationPayload(getOwnerOfficerDetailsApiPayload());
      return ownerOfficerPayload;
    }

    case UPDATE_APPLICATION_STEPS.ADDITIONAL_INTEREST: {
      const { getAdditionalInterestApplicationPayload } = useAdditionalInterestStore.getState();
      const additionalInterestPayload = createUpdateApplicationPayload(getAdditionalInterestApplicationPayload());
      return additionalInterestPayload;
    }

    case UPDATE_APPLICATION_STEPS.UNDERWRITING: {
      const { getUnderWritingStepPayload } = useUnderWritingQuestionStore.getState();
      const { applicationPayload } = getUnderWritingStepPayload();
      const underwritingPayload = createUpdateApplicationPayload(applicationPayload);
      return underwritingPayload;
    }

    case UPDATE_APPLICATION_STEPS.BROKER_CODES: {
      const { getBrokerConfigurationApiPayload } = useBrokerCodesStore.getState();
      const brokerCodesPayload = createUpdateApplicationPayload(getBrokerConfigurationApiPayload());
      return brokerCodesPayload;
    }

    case UPDATE_APPLICATION_STEPS.QUOTE_CHANGE_LIABILITY_LIMITS: {
      const { getLiabilityLimitsApiPayload } = useLiabilityLimitsStore.getState();
      const liabilityLimitsPayload = createUpdateApplicationPayload(getLiabilityLimitsApiPayload());
      return liabilityLimitsPayload;
    }

    case UPDATE_APPLICATION_STEPS.UPDATE_APPLICATION_EFFECTIVE_DATE_MODAL: {
      const { getEffectiveDateModalApiPayload } = useEffectiveDateModalStore.getState();
      const effectiveDateModalPayload = createUpdateApplicationPayload(getEffectiveDateModalApiPayload());
      return effectiveDateModalPayload;
    }

    case UPDATE_APPLICATION_STEPS.UPDATE_SELECTED_CARRIERS: {
      const { getUpdatedSelectedCarriersAPIPayload } = useDeleteCarrierStore.getState();
      const applicationPayload = getUpdatedSelectedCarriersAPIPayload();
      const updatedSelectedCarriersPayload = createUpdateApplicationPayload(applicationPayload);
      return updatedSelectedCarriersPayload;
    }

    case UPDATE_APPLICATION_STEPS.UPDATE_MODIFIERS: {
      const { getModifiersUpdateApplicationPayload } = useModifiersStore.getState();
      const modifiersApplicationPayload = createUpdateApplicationPayload(getModifiersUpdateApplicationPayload());
      return modifiersApplicationPayload;
    }

    default:
      return createUpdateApplicationPayload(updateApplicationPayload);
  }
};

export const getPolicyFromApplication = (applicationData?: IApplicationInfo): PolicyType | undefined =>
  applicationData?.policyDetails?.[0]?.policyType;

export const getSelectedCarriersFromApplication = (applicationData?: IApplicationInfo): Carrier[] =>
  applicationData?.applicationDetails?.carriersSelected || [];

export const getSelectedCarriersInfoFromApplication = (
  applicationData?: IApplicationInfo,
  availableCarriers: ICarrierInfo[] = [],
): ICarrierInfo[] => {
  const applicationSelectedCarriers = getSelectedCarriersFromApplication(applicationData);
  const applicationSelectedCarriersInfo = availableCarriers.filter((carrier) =>
    applicationSelectedCarriers.includes(carrier?.carrierId),
  );
  return applicationSelectedCarriersInfo;
};

export const getCurrentStepFromApplication = (applicationData: IApplicationInfo): string => {
  const { applicationStatus } = applicationData || {};
  const { statusCode } = applicationStatus || {};
  switch (statusCode) {
    case ApplicationStatus.IN_PROGRESS:
      return APPLICATION_TABS.GENERAL_QUESTIONS;
    case ApplicationStatus.QUOTING:
    case ApplicationStatus.QUOTED:
    case ApplicationStatus.REFERRED:
      return APPLICATION_TABS.QUOTES;
    case ApplicationStatus.PAYMENT_IN_PROGRESS:
    case ApplicationStatus.BIND_PENDING:
    case ApplicationStatus.BIND_IN_PROGRESS:
      return APPLICATION_TABS.PAYMENT;
    case ApplicationStatus.BOUND:
      return APPLICATION_TABS.CONFIRMATION;
    default:
      return APPLICATION_TABS.GENERAL_QUESTIONS;
  }
};

export const getLocationFromApplication = (
  applicationData?: IApplicationInfo,
  isPrimary: boolean = true,
): ILocationInfo | ILocationInfo[] | undefined => {
  return isPrimary
    ? applicationData?.locationDetails?.find((location) => location.isPrimary)
    : applicationData?.locationDetails;
};

const isUwAnswerDifferent = ({
  newAppDataUnderWritingQuestion,
  oldAppDataUnderWritingQuestion,
}: {
  newAppDataUnderWritingQuestion?: IUnderwritingDetailsInfo[];
  oldAppDataUnderWritingQuestion?: IUnderwritingDetailsInfo[];
}) => {
  const [largeNumberOfUnderWritingQuestion, smallNumberOfUnderWritingQuestion] =
    (newAppDataUnderWritingQuestion?.length || 0) > (oldAppDataUnderWritingQuestion?.length || 0)
      ? [newAppDataUnderWritingQuestion || [], oldAppDataUnderWritingQuestion || []]
      : [oldAppDataUnderWritingQuestion || [], newAppDataUnderWritingQuestion || []];

  return !isEmpty(differenceWith(largeNumberOfUnderWritingQuestion, smallNumberOfUnderWritingQuestion, isEqual));
};

export const isDifferentApplicationPayload = (appPayload: IUpdateApplicationInput) => {
  const { applicationData: oldAppPayload, currentStep } = useApplicationStore.getState();
  const newAppPayload = cloneDeep(appPayload);

  const newAppData = createUpdateApplicationPayload(newAppPayload);
  const oldAppData = createUpdateApplicationPayload(oldAppPayload!);

  if (currentStep === APPLICATION_TABS.UNDERWRITING) {
    const { underwritingDetails: newAppDataUnderWritingQuestion, ...restNewAppData } = newAppData;
    const { underwritingDetails: oldAppDataUnderWritingQuestion, ...restOldAppData } = oldAppData;
    return (
      !isEqual(removeUndefinedProperty(restNewAppData), removeUndefinedProperty(restOldAppData)) ||
      isUwAnswerDifferent({
        newAppDataUnderWritingQuestion: uniqBy(newAppDataUnderWritingQuestion?.reverse(), 'questionId'),
        oldAppDataUnderWritingQuestion: uniqBy(oldAppDataUnderWritingQuestion?.reverse(), 'questionId'),
      })
    );
  }
  return !isEqual(removeUndefinedProperty(newAppData), removeUndefinedProperty(oldAppData));
};

export const getApplicationPageErrorMessage = () => {
  const { applicationError, accountError } = useApplicationStore.getState();
  if (applicationError) {
    return applicationError?.[0]?.errorMessage;
  }

  if (accountError) {
    return accountError?.[0]?.errorMessage;
  }
};

export const showApplicationErrorRetryButton = () => {
  const { applicationError } = useApplicationStore.getState();
  return applicationError?.[0]?.status !== HTTPS_CODES.UNAUTHORIZED;
};

export const isOwnerOfficersEnabled = () => {
  const { applicationFieldsConfig } = useApplicationStore.getState();

  // This featureflag cleanup will be done once the dynamic questions feature is fully launched to prod.
  // Currently it is released progressively on prod and enabled for a agency network.

  const isAllFieldRequirementLevelNotAccepted = OWNER_OFFICER_FIELDS_PATH.every((field) => {
    const matchingField = applicationFieldsConfig?.find((config) => config?.fieldPath === field);
    return matchingField && matchingField?.requirementLevel === ApplicationFieldRequirementLevel.NOT_ACCEPTED;
  });
  return !isAllFieldRequirementLevelNotAccepted;
};

export const isAdditionalInterestEnabled = () => {
  const { applicationData, policyType } = useApplicationStore.getState();
  const carriers = getSelectedCarriersFromApplication(applicationData);
  return policyType === PolicyType.CGL && carriers.includes(Carrier.FIRST_INSURANCE);
};

export const isPastPolicyLossDisabled = () => {
  const { applicationFieldsConfig } = useApplicationStore.getState();
  const isAllFieldRequirementLevelNotAccepted = PAST_POLICY_LOSS_FIELDS_PATH.every((field) => {
    const matchingField = applicationFieldsConfig?.find((config) => config?.fieldPath === field);
    return matchingField && matchingField?.requirementLevel === ApplicationFieldRequirementLevel.NOT_ACCEPTED;
  });
  return isAllFieldRequirementLevelNotAccepted;
};

export const modifyVisibilityOfFields = (componentFormFieldsConfig: IApplicationQuestion[]): IApplicationQuestion[] => {
  const clonedFormFieldsConfig = cloneDeep(componentFormFieldsConfig);
  const computedFormFieldsConfig: IApplicationQuestion[] = [];

  const { applicationFieldsConfig } = useApplicationStore.getState();

  if (clonedFormFieldsConfig?.length > 0) {
    clonedFormFieldsConfig?.map((formField) => {
      // First we check whether there is fieldMapping present in the form field config.
      // If TRUE, compute the fieldMapping related logic below.
      // if FALSE, directly push the form field in the computed config as we want the element to be present in the if there are info regarding fieldMapping.
      if (APPLICATION_QUESTION_INTERFACE_KEYS.FIELD_MAPPING in formField) {
        // Find the form field fieldMapping index from the applicationFieldsConfig present in the application store.
        const selectedFieldConfigIndex =
          applicationFieldsConfig?.findIndex((applicationField) => {
            return applicationField?.fieldPath === formField?.fieldMapping;
          }) ?? -1;

        // Now we check whether form field fieldMapping exisits in applicationFieldsConfig and the REQUIREMENT for that field is NOT_ACCEPTED.
        // If TRUE, modify the form field with an attribute formField.hidden = true;,
        // If FALSE, directly push the form field in the computed config.
        if (selectedFieldConfigIndex !== -1) {
          if (
            applicationFieldsConfig?.[selectedFieldConfigIndex]?.requirementLevel ===
            ApplicationFieldRequirementLevel.NOT_ACCEPTED
          ) {
            formField.hidden = true;
          }
          // Change the config required value to FALSE if the requirementLevel is OPTIONAL
          if (
            applicationFieldsConfig?.[selectedFieldConfigIndex]?.requirementLevel ===
            ApplicationFieldRequirementLevel.OPTIONAL
          ) {
            const fieldRules = formField?.rules;
            if (fieldRules && fieldRules?.length > 0) {
              fieldRules[0].required = false;
            }
          }
        }
      }
      computedFormFieldsConfig.push(formField);
    });
    return computedFormFieldsConfig;
  } else {
    // This is a fallback for the default case.
    return clonedFormFieldsConfig;
  }
};

export const getVisibleFormElementKeys = (fieldConfig: IApplicationQuestion[]): NamePath[] => {
  const fieldConfigKeys = fieldConfig?.map((field) => field?.dataIndex) || [];
  let visibleFieldKeys: NamePath[] = [];

  if (fieldConfig) {
    visibleFieldKeys = fieldConfig
      .filter((field) => !field?.hidden && field?.rules?.[0]?.required === true) // Exclude keys for form validation which are NOT_ACCEPTED, OPTIONAL
      .map((field) => field?.dataIndex);
    return visibleFieldKeys;
  }
  return fieldConfigKeys;
};

export const getRequirementLevelOfField = (fieldName: ApplicationFieldPathEnum) => {
  const { applicationFieldsConfig } = useApplicationStore.getState();
  const applicationFieldConfig = applicationFieldsConfig?.find((fieldConfig) => fieldConfig?.fieldPath === fieldName);
  if (applicationFieldConfig) {
    return applicationFieldConfig?.requirementLevel;
  }
  return ApplicationFieldRequirementLevel.REQUIRED_FOR_QUOTE;
};
