import { CfLegalEntityType } from '@coverforce-platform/cf-common-types';
import dayjs from 'dayjs';

import { useOwnerOfficerDetailsStore } from '../../components/ownerOfficerDetails/store';
import { DATE_FORMAT, FALSY_VALUES } from '../../globalConstants';
import { useApplicationStore } from '../../pages/v2/application/store';
import { ANTD_QUESTION_TYPE } from '../applicationQuestion/constants';
import { IOwnerDetailsFormConfig, IOwnerDetailsModalStore } from './interface';
import { useOwnerOfficerDetailsModalStore } from './store';

export const OWNER_OFFICER_DETAILS_MODAL_STORE_META_DATA = {
  SET_OWNER_OFFICER_LOADING: 'SET_OWNER_OFFICER_LOADING',
  SET_OWNER_OFFICER_QUESTIONS: 'SET_OWNER_OFFICER_QUESTIONS',
  SET_OWNER_OFFICER_MODAL_FORM: 'SET_OWNER_OFFICER_MODAL_FORM',
  SET_EDITED_OWNER_OFFICER_DETAILS: 'SET_EDITED_OWNER_OFFICER_DETAILS',
  SET_CLASS_CODES: 'SET_CLASS_CODES',
  UPDATE_OWNER_OFFICER_MODAL_STORE_BY_KEY: 'UPDATE_OWNER_OFFICER_MODAL_STORE_BY_KEY',
  CLEAR_OWNER_OFFICER_MODAL_STORE: 'CLEAR_OWNER_OFFICER_MODAL_STORE',
  ANONYMOUS_ACTION_NAME: 'OWNER_OFFICER_MODAL_ANONYMOUS_ACTION',
  STORE_NAME: 'Owner Officer Modal Store',
};

export const OWNER_OFFICER_QUESTION_KEYS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  ANNUAL_PAYROLL: 'annualPayroll',
  LOCATION_ID: 'cfLocationId',
  CLASS_CODE: 'ncciStateClassCode',
  DOB: 'dateOfBirth',
  OFFICIAL_TITLE: 'officialTitle',
  OWNERSHIP_PERCENTAGE: 'ownershipPercentage',
};

export const OWNER_OFFICER_DETAILS_MODAL_MESSAGE = {
  FAILED_TO_FETCH_STATE_CLASS_CODES: 'FAILED_TO_FETCH_STATE_CLASS_CODES',
  FAILED_TO_POPULATE_DATA: 'FAILED_TO_POPULATE_DATA',
};

export const OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS: { [key: string]: keyof IOwnerDetailsModalStore } = {
  OWNER_OFFICER_DETAILS_MODAL_FORM: 'ownerOfficerDetailsModalForm',
  OWNER_OFFICER_DETAILS_MODAL_QUESTION_ELEMENTS: 'questionElements',
  OWNER_OFFICER_DETAILS_MODAL_EDIT: 'editedOwnerOfficerDetails',
  OWNER_OFFICER_DETAILS_MODAL_LOADING: 'ownerOfficerModalLoading',
};

export const OWNER_OFFICER_QUESTIONS: IOwnerDetailsFormConfig[] = [
  {
    type: ANTD_QUESTION_TYPE.STRING,
    name: 'First Name',
    dataIndex: 'firstName',
    placeholder: 'First Name',
    rules: [
      {
        required: true,
        message: 'Please enter First Name.',
        validator: (_: any, value: any) => {
          if (FALSY_VALUES?.includes(value?.trim())) {
            return Promise.reject(new Error('Please enter First Name.'));
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.STRING,
    name: 'Last Name',
    dataIndex: 'lastName',
    placeholder: 'Last Name',
    rules: [
      {
        required: true,
        message: 'Please enter Last Name.',
        validator: (_: any, value: any) => {
          if (FALSY_VALUES?.includes(value?.trim())) {
            return Promise.reject(new Error('Please enter Last Name.'));
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.MONEY,
    name: 'Annual Payroll',
    dataIndex: 'annualPayroll',
    rules: [
      {
        required: true,
        validator: (_: any, value: any) => {
          if (FALSY_VALUES?.includes(value)) {
            return Promise.reject('Please enter Annual Payroll.');
          }
          if (Number(value) <= 0) {
            return Promise.reject('Value should be greater than 0.');
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.DROPDOWN,
    name: 'Location',
    dataIndex: 'cfLocationId',
    placeholder: 'Select Location',
    rules: [{ required: true, message: 'Please select a Location.' }],
    options: [],
    loading: false,
    filterOption: (input: string, option: any) =>
      (option?.label as unknown as string)?.toLowerCase().includes(input?.toLowerCase()),
    getPopupContainer: () => document.body,
  },
  {
    type: ANTD_QUESTION_TYPE.DROPDOWN,
    name: 'WC Class Code',
    dataIndex: 'ncciStateClassCode',
    placeholder: 'Start typing to search..',
    rules: [{ required: true, message: 'Please select Employee type.' }],
    options: [],
    disabled: true,
    loading: false,
    filterOption: (input: string, option: any) =>
      (option?.label as unknown as string)?.toLowerCase().includes(input?.toLowerCase()),
    getPopupContainer: () => document.body,
  },
  {
    type: ANTD_QUESTION_TYPE.DATE,
    name: 'Date Of Birth',
    dataIndex: 'dateOfBirth',
    placeholder: 'Date Of Birth',
    format: DATE_FORMAT.MMDDYYYY,
    rules: [{ required: false, message: 'Please enter Date Of Birth.' }],
    disabledDate: (date: dayjs.Dayjs) => date && date > dayjs().local(),
    hidden: true,
    style: { width: '100%' },
  },
  {
    type: ANTD_QUESTION_TYPE.DROPDOWN,
    name: 'Owner/Officer Title',
    dataIndex: 'officialTitle',
    placeholder: 'Select Owner/Officer Title',
    rules: [{ required: true, message: 'Please select an Owner/Officer Title.' }],
    options: [],
    hidden: true,
    filterOption: (input: string, option: any) =>
      (option?.label as unknown as string)?.toLowerCase().includes(input?.toLowerCase()),
    getPopupContainer: () => document.body,
  },
  {
    type: ANTD_QUESTION_TYPE.NUMBER,
    name: 'Ownership Percentage',
    dataIndex: 'ownershipPercentage',
    placeholder: 'Please enter Ownership Percentage',
    rules: [
      {
        required: true,
        validator: (_: any, value: any) => {
          const { ownerOfficerDetailsList } = useOwnerOfficerDetailsStore.getState();
          const { editedOwnerOfficerDetails } = useOwnerOfficerDetailsModalStore.getState();
          const { applicationData } = useApplicationStore.getState();
          if (!value?.toString()?.trim()) {
            return Promise.reject('Please enter Ownership Percentage.');
          }
          const totalPercentage = ownerOfficerDetailsList?.reduce(
            (accumulator, ownersDetails, index) =>
              accumulator +
              (editedOwnerOfficerDetails?.index === index ? 0 : Number(ownersDetails?.ownershipPercentage || 0)),
            0,
          );
          if (totalPercentage + Number(value) > 100) {
            return Promise.reject(
              applicationData?.companyStructure?.legalEntityType === CfLegalEntityType.INDIVIDUAL
                ? 'Ownership should be less than or equal to 100%'
                : 'The ownership distribution across multiple owners should be less than or equal to 100%',
            );
          }
          return Promise.resolve();
        },
      },
    ],
  },
];
