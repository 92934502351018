import { IPaymentStore } from './interface';

export const PAYMENT_STORE_META_DATA = {
  STORE_NAME: 'Payments Store',
  ANONYMOUS_ACTION_NAME: 'PAYMENTS_ACTION',
  SET_PAYMENT_PLAN_FETCHING: 'SET_PAYMENT_PLAN_FETCHING',
  SET_IS_BOUNDING: 'SET_IS_BOUNDING',
  SET_PAYMENT_OPTION: 'SET_PAYMENT_OPTION',
  SET_POLICY_DETAILS: 'SET_POLICY_DETAILS',
  UPDATE_PAYMENT_STORE_BY_KEY: 'UPDATE_PAYMENT_STORE_BY_KEY',
  SET_PAYMENT_PLANS: 'SET_PAYMENT_PLANS',
  CLEAR_PAYMENT_STORE: 'CLEAR_PAYMENT_STORE',
};

export const PAYMENT_ERROR_MESSAGE = {
  FETCH_PAYMENT_PLANS: 'Some error occurred while fetching payment plans!',
  SET_PAYMENT_PLAN: 'Some error occurred while selecting the payment plan!',
  BIND_POLICY: 'Some error occurred while binding the policy!',
  GET_PAYMENT_OPTIONS: 'Some error occurred while fetching payment options!',
  MAKE_PAYMENT: 'Some error occurred while making the payment!',
};

export const PAYMENT_STORE_KEYS: { [key: string]: keyof IPaymentStore } = {
  POLICY_DETAILS: 'policyDetails',
  PAYMENT_FORM: 'paymentForm',

  // payment integration v2
  IS_PAYMENT_PLAN_CONFIRMED: 'isPaymentPlanConfirmed',
  IS_PAYMENT_PLAN_CONFIRMED_LOADING: 'isPaymentPlanConfirmedLoading',
  PAYMENT_OPTIONS_API_DATA: 'paymentOptionsData',
  MAKE_PAYMENT_API_DATA: 'makePaymentData',
  SET_PAYMENT_PLAN_API_DATA: 'setPaymentPlanData',
  // payment integration v2 ends
};

export const POLICY_FALLBACK_FAILURE_REASON =
  "The bind could not be completed by the Carrier. Please try again later or bridge over to the Carrier's Agent Portal";

export const GA_CATEGORY = {
  PAYMENT_TAB_CARD: 'paymentTab_card',
  PAYMENT_TAB_BIND_CONFIRM: 'paymentTab_bindConfirmModal',
};

export const GA_ACTION = {
  BIND_POLICY_BTN_CLICK: 'bindPolicyBtn_click',
  CLOSE_BIND_CONFIRM_MODAL_BTN_CLICK: 'closeBindConfirmModalBtn_click',
};

export const GA_LABEL = {
  BIND_POLICY: 'Bind policy',
  CLOSE_BIND_CONFIRM_MODAL: 'Close bind confirmation modal',
};
