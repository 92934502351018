import { useEffect } from 'react';

import { useApplicationStore } from '../../pages/v2/application/store';
import CFForm, { useForm } from '../../ui-core/V2/cfForm/cfForm';
import CFFormItem from '../../ui-core/V2/cfFormItem/cfFormItem';
import CFModal from '../../ui-core/V2/cfModal/cfModal';
import { useCFModalStore } from '../../ui-core/V2/cfModal/store';
import { cloneDeep } from '../../utils/lodash';
import { useAdditionalInterestStore } from '../additionalInterest/store';
import ApplicationQuestion from '../applicationQuestion/applicationQuestion';
import { IApplicationQuestion } from '../applicationQuestion/interface';
import { ADDITIONAL_INTEREST_MODAL_STORE_KEYS, ADDITIONAL_INTEREST_QUESTIONS } from './constants';
import { useAdditionalInterestModalStore } from './store';

const AdditionalInterestModal = () => {
  const [form] = useForm();
  const { clearCfModal } = useCFModalStore();
  const { isReadOnlyApplication } = useApplicationStore();
  const { additionalInterestList } = useAdditionalInterestStore();
  const {
    additionalInterestInfoIndex,
    addOrUpdateAdditionalInterestDetails,
    updateAdditionalInterestModalStoreByKey,
    validateAdditionalInterestModalDetails,
    clearAdditionalInterestModal,
  } = useAdditionalInterestModalStore();

  useEffect(() => {
    updateAdditionalInterestModalStoreByKey(ADDITIONAL_INTEREST_MODAL_STORE_KEYS.ADDITIONAL_INTEREST_MODAL_FORM, form);

    return () => {
      clearAdditionalInterestModal();
      clearCfModal();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (additionalInterestInfoIndex !== -1) {
      form?.setFieldsValue(cloneDeep(additionalInterestList[additionalInterestInfoIndex]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdditionalInterestModalSubmit = async () => {
    const isAdditionalInterestModalFormValid = await validateAdditionalInterestModalDetails();
    if (isAdditionalInterestModalFormValid) {
      addOrUpdateAdditionalInterestDetails();
      clearAdditionalInterestModal();
      clearCfModal();
    }
  };

  return (
    <CFModal
      title='Enter Additional Interest Details'
      okText={`${additionalInterestInfoIndex === -1 ? 'Add' : 'Update'} Additional Interest Details`}
      onOk={handleAdditionalInterestModalSubmit}
      okButtonProps={{
        id: 'btn_ok_additional_interest_details',
        disabled: isReadOnlyApplication,
      }}
      cancelButtonProps={{
        id: 'btn_cancel_additional_interest_details',
        disabled: isReadOnlyApplication,
      }}
      width={600}
    >
      <CFForm id='form_additional_interest_modal' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={form}>
        {ADDITIONAL_INTEREST_QUESTIONS?.map((question: IApplicationQuestion) => {
          if (question.hidden) {
            return <></>;
          }
          return (
            <CFFormItem
              shouldUpdate
              noStyle
              id={`form_item_id_question_${question.dataIndex}`}
              key={`form-item-key-question-${question.dataIndex}`}
            >
              {() => {
                return (
                  <ApplicationQuestion
                    question={question}
                    key={`question-${question.dataIndex}`}
                    allQuestions={ADDITIONAL_INTEREST_QUESTIONS}
                    form={form}
                    disabled={question.disabled || isReadOnlyApplication}
                  />
                );
              }}
            </CFFormItem>
          );
        })}
      </CFForm>
    </CFModal>
  );
};

export default AdditionalInterestModal;
