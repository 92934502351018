import { IGeneralQuestionsStore } from './interface';

export const LEGAL_ENTITY_TYPE = 'legalEntityType';

export const GENERAL_QUESTIONS_ACTIONS = {
  FETCH_LEGAL_ENTITIES: 'GQ/FETCH_LEGAL_ENTITIES',
  SET_GQ_FORM: 'GQ/SET_GQ_FORM',
  CLEAR_GQ: 'GQ/CLEAR_GQ',
  ANONYMOUS_ACTION: 'GQ/DEFAULT_ACTION',
  STORE_NAME: 'General Question V2',
  UPDATE_GQ_BY_KEY: 'UPDATE_GQ_BY_KEY',
};

export const INVALID_MSG = {
  EFFECTIVE: 'Invalid insurance effective date.',
  EXPIRY: 'Policy Expiry date invalid.',
  STATUS: 'Invalid application status.',
};

export const GQ_FORM_KEY = {
  PRIMARY_ADDRESS: 'primaryAddress',
  MAILING_ADDRESS: 'mailingAddress',
  INSURANCE_EFFECTIVE_DATE: 'insuranceEffectiveDate',
  INSURANCE_EXPIRY_DATE: 'insuranceExpiryDate',
  LEGAL_ENTITY_TYPE: 'legalEntityType',
  IS_NON_PROFIT: 'isNonProfit',
  NUMBER_OF_FULL_TIME_EMPLOYEES: 'numberOfFullTimeEmployees',
  NUMBER_OF_PART_TIME_EMPLOYEES: 'numberOfPartTimeEmployees',
  TOTAL_EMPLOYEE_PAYROLL: 'totalEmployeePayroll',
  YEARS_OR_EXP: 'yearsOfExperience',
  ANNUAL_REVENUE: 'annualRevenue',
  FEIN: 'fein',
};

export const GQ_KEYS: { [k: string]: keyof IGeneralQuestionsStore } = {
  GENERAL_QUESTION_LOADING: 'isGeneralQuestionsLoading',
};

export const AUTOFILL_GQ_KEYS = ['businessName', 'primaryAddress', 'firstName', 'lastName', 'phone', 'email'];

export const FIEN_WARNING =
  'All the digits are the same. Certain carriers will decline the application for an invalid FEIN.';
