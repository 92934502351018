import {
  IApplicationInfo,
  IBindQuoteInput,
  IBindQuoteOutput,
  IGetPaymentOptionsInput,
  IGetPaymentOptionsOutput,
  IGetPaymentPlansInput,
  IGetPaymentPlansOutput,
  IGetQuotePaymentDetailsInput,
  IGetQuotePaymentDetailsOutput,
  IMakePaymentTransactionInput,
  IMakePaymentTransactionOutput,
  ISelectPaymentPlanInput,
  ISelectPaymentPlanOutput,
} from '@coverforce-platform/cf-common-api-model';
import { ApplicationStatus, QuotePaymentStatus } from '@coverforce-platform/cf-common-types';
import { AxiosResponse } from 'axios';

import axiosApplicationService from '../../axios/applicationService/instance';
import axiosApplicationServiceV2 from '../../axios/applicationServiceV2/instance';
import { IPoll } from '../../globalInterface';
import { createQueryUrl } from '../../utils/createQueryUrl';
import sleep from '../../utils/sleep';
import { APPLICATION_SERVICE_ENDPOINTS } from '../helpers/ApiRoutes';
import { getApplication } from './applicationV2';

const getPaymentPlans = async (params: IGetPaymentPlansInput): Promise<IGetPaymentPlansOutput> => {
  const getPaymentPlansApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.GET_PAYMENT_PLANS(params) });

  try {
    const response: AxiosResponse = await axiosApplicationService.get(getPaymentPlansApiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const selectPaymentPlan = async (params: ISelectPaymentPlanInput): Promise<ISelectPaymentPlanOutput> => {
  const selectPaymentPlanApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.SELECT_PAYMENT_PLAN });

  try {
    const response: AxiosResponse = await axiosApplicationService.post(selectPaymentPlanApiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const bindApplication = async (params: IBindQuoteInput): Promise<IBindQuoteOutput> => {
  const bindApplicationApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.BIND_APPLICATION });

  try {
    const response: AxiosResponse = await axiosApplicationService.post(bindApplicationApiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const bindApplicationV2 = async (params: IBindQuoteInput): Promise<IBindQuoteOutput> => {
  const bindApplicationApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.BIND_APPLICATION });

  try {
    const response: AxiosResponse = await axiosApplicationServiceV2.post(bindApplicationApiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const pollForApplication = async (applicationId: string, pollingData?: IPoll): Promise<IApplicationInfo> => {
  const { frequencyInMs = 3000, maxRetries = 300 } = pollingData || {};
  let retries = 0;

  let applicationData;

  do {
    await sleep(frequencyInMs);
    applicationData = await getApplication({ applicationId });
    retries++;
  } while (
    applicationData?.applicationStatus?.statusCode === ApplicationStatus.BIND_IN_PROGRESS &&
    retries <= maxRetries &&
    window.location.pathname.includes(applicationId)
  );

  return applicationData;
};

const pollForPaymentDetails = async (
  params: IGetQuotePaymentDetailsInput,
  pollingData?: IPoll,
): Promise<IGetQuotePaymentDetailsOutput> => {
  //TODO: Frequence is 10 sec in doc, but i think it should be 3 sec
  // In near future, the polling wait text and frequency will be parameterized via backend
  const { frequencyInMs = 3000, maxRetries = 100 } = pollingData || {};

  let retries = 0;

  let paymentDetails;

  do {
    await sleep(frequencyInMs);
    paymentDetails = await getPaymentDetails(params);
    retries++;
  } while (
    paymentDetails?.quotePaymentStatus === QuotePaymentStatus.IN_PROGRESS &&
    retries <= maxRetries &&
    window.location.pathname.includes(params.applicationId)
  );

  return paymentDetails;
};

const getPaymentOptions = async (params: IGetPaymentOptionsInput): Promise<IGetPaymentOptionsOutput> => {
  const getPaymentOptionsApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.GET_PAYMENT_OPTIONS(params) });

  try {
    const response: AxiosResponse = await axiosApplicationService.get(getPaymentOptionsApiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const makePayment = async (params: IMakePaymentTransactionInput): Promise<IMakePaymentTransactionOutput> => {
  const makePaymentApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.MAKE_PAYMENT });

  try {
    const response: AxiosResponse = await axiosApplicationService.post(makePaymentApiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getPaymentDetails = async (params: IGetQuotePaymentDetailsInput): Promise<IGetQuotePaymentDetailsOutput> => {
  const getPaymentDetailsApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.GET_PAYMENT_DETAILS(params) });

  try {
    const response: AxiosResponse = await axiosApplicationService.get(getPaymentDetailsApiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export {
  getPaymentPlans,
  selectPaymentPlan,
  bindApplication,
  bindApplicationV2,
  pollForApplication,
  getPaymentOptions,
  makePayment,
  getPaymentDetails,
  pollForPaymentDetails,
};
