import {
  ApplicationFieldPathEnum,
  ApplicationFieldRequirementLevel,
  DependencyType,
  NatureOfConstructionProject,
} from '@coverforce-platform/cf-common-types';
import dayjs from 'dayjs';

import {
  deNormalizeFeinNumber,
  getEmailRegex,
  getFeinNumberRegex,
  getPhoneNumberRegex,
  normalizePhoneNumber,
} from '../../helpers/Utils';
import { getRequirementLevelOfField } from '../../pages/v2/application/utils';
import { getFeinNumberWarningRegex } from '../../utils/regex';
import { ANTD_QUESTION_TYPE } from '../applicationQuestion/constants';
import { FIEN_WARNING } from './constants';

export const GENERAL_QUESTIONS_ELEMENTS = [
  {
    type: ANTD_QUESTION_TYPE.STRING,
    name: 'Name of Business',
    dataIndex: 'businessName',
    fieldMapping: ApplicationFieldPathEnum.BUSINESS_NAME,
    placeholder: 'Business Name',
    rules: [
      {
        required: true,
        validator: (_: any, value: any) => {
          if (
            getRequirementLevelOfField(ApplicationFieldPathEnum.BUSINESS_NAME) ===
            ApplicationFieldRequirementLevel.OPTIONAL
          ) {
            if (!value?.trim()) {
              return Promise.resolve();
            }
          }
          if (!value?.trim()) {
            return Promise.reject(new Error('Please enter Business Name.'));
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.STRING,
    name: 'DBA Name',
    dataIndex: 'dba',
    fieldMapping: ApplicationFieldPathEnum.DOING_BUSINESS_AS,
    placeholder: 'Doing Business As',
    tooltip: "A 'Doing Business As' (DBA) Name is a business' assumed, trade or fictitious name",
    rules: [
      {
        required: true,
        validator: (_: any, value: any) => {
          if (
            getRequirementLevelOfField(ApplicationFieldPathEnum.DOING_BUSINESS_AS) ===
            ApplicationFieldRequirementLevel.OPTIONAL
          ) {
            if (!value?.trim()) {
              return Promise.resolve();
            }
          }
          if (!value?.trim()) {
            return Promise.reject(new Error('Please enter DBA Name.'));
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.ADDRESS,
    name: 'Primary Address',
    dataIndex: 'primaryAddress',
    rules: [
      {
        required: true,
        message: '',
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.RADIO,
    name: 'Mailing address is same as Primary address?',
    dataIndex: 'mailingAddressSame',
    dependentQuestions: [
      {
        dependencyType: DependencyType.CONDITIONAL,
        condition: 'no',
        childQuestionIds: ['mailingAddress'],
      },
    ],
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.ADDRESS,
    name: 'Mailing Address',
    dataIndex: 'mailingAddress',
    hasParent: true,
    rules: [
      {
        required: true,
        message: '',
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.DATE,
    subtype: 'year',
    name: 'Year the Business was started',
    dataIndex: 'businessYearOfFounding',
    fieldMapping: ApplicationFieldPathEnum.YEAR_OF_FOUNDING,
    rules: [
      {
        required: true,
        message: 'Please enter Business Year of Founding.',
      },
    ],
    disabledDate: (year: dayjs.Dayjs) => year && year > dayjs().endOf('year'),
  },
  {
    type: ANTD_QUESTION_TYPE.STRING,
    name: 'Website URL',
    dataIndex: 'businessWebsite',
    fieldMapping: ApplicationFieldPathEnum.BUSINESS_WEBSITE,
    placeholder: 'www.example.com',
    rules: [
      {
        required: true,
        validator: (_: any, value: any) => {
          if (
            getRequirementLevelOfField(ApplicationFieldPathEnum.BUSINESS_WEBSITE) ===
            ApplicationFieldRequirementLevel.OPTIONAL
          ) {
            if (!value?.trim()) {
              return Promise.resolve();
            }
          }
          if (!value?.trim()) {
            return Promise.reject(new Error('Please enter Website URL.'));
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.STRING,
    name: 'First Name ',
    dataIndex: 'firstName',
    fieldMapping: ApplicationFieldPathEnum.CONTACT_FIRST_NAME,
    placeholder: 'First Name',
    rules: [
      {
        required: true,
        validator: (_: any, value: any) => {
          if (
            getRequirementLevelOfField(ApplicationFieldPathEnum.CONTACT_FIRST_NAME) ===
            ApplicationFieldRequirementLevel.OPTIONAL
          ) {
            if (!value?.trim()) {
              return Promise.resolve();
            }
          }
          if (!value?.trim()) {
            return Promise.reject(new Error('Please enter your First Name.'));
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.STRING,
    name: 'Last Name',
    dataIndex: 'lastName',
    fieldMapping: ApplicationFieldPathEnum.CONTACT_LAST_NAME,
    placeholder: 'Last Name',
    rules: [
      {
        required: true,
        validator: (_: any, value: any) => {
          if (
            getRequirementLevelOfField(ApplicationFieldPathEnum.CONTACT_LAST_NAME) ===
            ApplicationFieldRequirementLevel.OPTIONAL
          ) {
            if (!value?.trim()) {
              return Promise.resolve();
            }
          }
          if (!value?.trim()) {
            return Promise.reject(new Error('Please enter your Last Name.'));
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.PHONE,
    name: 'Phone Number',
    dataIndex: 'phone',
    fieldMapping: ApplicationFieldPathEnum.CONTACT_PHONE,
    rules: [
      {
        required: true,
        validator: (_: any, value: any) => {
          value = normalizePhoneNumber(value?.trim());
          if (
            getRequirementLevelOfField(ApplicationFieldPathEnum.CONTACT_PHONE) ===
            ApplicationFieldRequirementLevel.OPTIONAL
          ) {
            if (!value?.trim()) {
              return Promise.resolve();
            }
          }
          if (value && value.toString().length >= 14 && getPhoneNumberRegex(value.toString())) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please enter a valid 10 digit phone number.'));
        },
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.STRING,
    name: 'Email',
    dataIndex: 'email',
    fieldMapping: ApplicationFieldPathEnum.CONTACT_EMAIL,
    placeholder: 'email@email.com',
    rules: [
      {
        required: true,
        pattern: getEmailRegex(),
        message: 'Please enter a valid Email address. ',
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.FEIN,
    name: 'FEIN',
    dataIndex: 'fein',
    fieldMapping: ApplicationFieldPathEnum.FEIN,
    placeholder: 'XX-XXXXXXX',
    tooltip:
      'Federal Employer Identification Number (FEIN) is a unique nine-digit number assigned by the Internal Revenue Service (IRS) to business entitles operating in the United States for the purposes of identification',

    rules: [
      {
        required: true,
        validator: (_: any, value: string) => {
          if (getRequirementLevelOfField(ApplicationFieldPathEnum.FEIN) === ApplicationFieldRequirementLevel.OPTIONAL) {
            if (!value?.trim()) {
              return Promise.resolve();
            }
          }
          if (getFeinNumberRegex(value?.toString()?.trim())) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please enter a valid 9 digit FEIN number.'));
        },
      },
      {
        warningOnly: true,
        validator: (_: any, value: string) => {
          const deNormalizedValue = deNormalizeFeinNumber(value);

          if (deNormalizedValue?.length === 9 && getFeinNumberWarningRegex()?.test(deNormalizedValue)) {
            return Promise.reject(new Error(FIEN_WARNING));
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.TEXTAREA,
    name: 'Description of Business operations',
    dataIndex: 'natureOfOperations',
    fieldMapping: ApplicationFieldPathEnum.NATURE_OF_OPERATIONS,
    rows: 2,
    maxLength: 450,
    showCount: true,
    placeholder: 'Please describe what your business does in a few words',
    tooltip: 'In a few sentences, describe the business and key business operations',
    rules: [
      {
        required: true,
        validator: (_: any, value: any) => {
          if (
            getRequirementLevelOfField(ApplicationFieldPathEnum.NATURE_OF_OPERATIONS) ===
            ApplicationFieldRequirementLevel.OPTIONAL
          ) {
            if (!value?.trim()) {
              return Promise.resolve();
            }
          }
          if (!value?.trim()) {
            return Promise.reject(new Error('Please describe the operations of the business in 450 characters.'));
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.NUMBER,
    name: 'Years of management experience in industry',
    dataIndex: 'yearsOfExperience',
    fieldMapping: ApplicationFieldPathEnum.YEARS_OF_MANAGEMENT_EXPERIENCE,
    rules: [
      {
        required: true,
        validator: (_: any, value: any) => {
          if (!value?.trim() || Number(value) < 0 || Number(value) > 99) {
            return Promise.reject('Please enter a value between 0 and 99.');
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.MONEY,
    name: 'Annual revenue',
    dataIndex: 'annualRevenue',
    fieldMapping: ApplicationFieldPathEnum.ANNUAL_REVENUE,
    tooltip: 'Current or expected annual revenue',
    rules: [
      {
        required: true,
        validator: (_: any, value: string) => {
          if (
            getRequirementLevelOfField(ApplicationFieldPathEnum.ANNUAL_REVENUE) ===
            ApplicationFieldRequirementLevel.OPTIONAL
          ) {
            if (!value?.trim()) {
              return Promise.resolve();
            }
          }
          if (!value) {
            return Promise.reject('Please enter Annual Revenue.');
          }
          if (Number(value) <= 0) {
            return Promise.reject('Value should be greater than 0.');
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.DROPDOWN,
    name: 'Business entity type',
    dataIndex: 'legalEntityType',
    fieldMapping: ApplicationFieldPathEnum.LEGAL_ENTITY_TYPE,
    placeholder: 'Start typing to search Business entity type',
    options: [],
    filterOption: (input: string, option: any) =>
      (option?.label as unknown as string)?.toLowerCase().includes(input?.toLowerCase()),
    rules: [{ required: true, message: 'Please select Business Entity Type.' }],
  },
  {
    type: ANTD_QUESTION_TYPE.RADIO,
    name: 'Is the Business non-profit?',
    dataIndex: 'isNonProfit',
    fieldMapping: ApplicationFieldPathEnum.IS_NON_PROFIT,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
    tooltip:
      'A non-profit organization is one that qualifies for tax-exempt status by the IRS because its mission and purpose are to further a social cause and provide a public benefit',

    rules: [{ required: true, message: 'Please select is Non profit or not.' }],
  },
  {
    type: ANTD_QUESTION_TYPE.DATE,
    name: 'Policy Effective Date',
    dataIndex: 'insuranceEffectiveDate',
    fieldMapping: ApplicationFieldPathEnum.INSURANCE_EFFECTIVE_DATE,
    rules: [{ required: true, message: 'Please enter Policy Effective Date.' }],
    tooltip: 'The effective date for insurance coverage is the date that the coverage is officially active',

    format: 'MM-DD-YYYY',
    disabledDate: (date: dayjs.Dayjs) => date && date <= dayjs().local().subtract(1, 'day'),
  },
  {
    type: ANTD_QUESTION_TYPE.DATE,
    name: 'Policy Expiration Date',
    dataIndex: 'insuranceExpiryDate',
    fieldMapping: ApplicationFieldPathEnum.INSURANCE_EXPIRY_DATE,
    rules: [{ required: true, message: 'Please enter Policy Expiry Date.' }],
    format: 'MM-DD-YYYY',
    disabledDate: (date: dayjs.Dayjs) => date && date <= dayjs().local().subtract(0, 'day'),
  },
  {
    type: ANTD_QUESTION_TYPE.NUMBER,
    name: 'Total Number of Full-Time Employees',
    dataIndex: 'numberOfFullTimeEmployees',
    fieldMapping: ApplicationFieldPathEnum.NUMBER_OF_FULL_TIME_EMPLOYEES,
    placeholder: 'Total Number of Full-Time Employees',
    min: 0,
    rules: [
      {
        required: true,
        validator: (_: any, value: any) => {
          if (
            getRequirementLevelOfField(ApplicationFieldPathEnum.NUMBER_OF_FULL_TIME_EMPLOYEES) ===
            ApplicationFieldRequirementLevel.OPTIONAL
          ) {
            if (!value && value !== 0) {
              return Promise.resolve();
            }
          }
          if (!value && value !== 0) {
            return Promise.reject('Please enter Total Number of Full-Time Employees.');
          }
          return Promise.resolve();
        },
      },
      ({ getFieldValue }: { getFieldValue: Function }) => ({
        validator(_: any, value: any) {
          if (!value && value !== 0) {
            return Promise.resolve();
          }
          const partTimeEmp = Number(getFieldValue(['numberOfPartTimeEmployees']) || 0);
          const totalEmployeePayroll = Number(getFieldValue(['totalEmployeePayroll']) || 0);
          if (partTimeEmp === 0 && Number(value) === 0 && totalEmployeePayroll > 0) {
            return Promise.reject('Sum of Full-Time and Part-Time Employees should be greater than 0.');
          }
          return Promise.resolve();
        },
      }),
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.NUMBER,
    name: 'Total Number of Part-Time Employees',
    dataIndex: 'numberOfPartTimeEmployees',
    fieldMapping: ApplicationFieldPathEnum.NUMBER_OF_PART_TIME_EMPLOYEES,
    placeholder: 'Total Number of Part-Time Employees',
    min: 0,
    rules: [
      {
        required: true,
        validator: (_: any, value: any) => {
          if (
            getRequirementLevelOfField(ApplicationFieldPathEnum.NUMBER_OF_PART_TIME_EMPLOYEES) ===
            ApplicationFieldRequirementLevel.OPTIONAL
          ) {
            if (!value && value !== 0) {
              return Promise.resolve();
            }
          }
          if (!value && value !== 0) {
            return Promise.reject('Please enter Total Number of Part-Time Employees.');
          }
          return Promise.resolve();
        },
      },
      ({ getFieldValue }: { getFieldValue: Function }) => ({
        validator(_: any, value: any) {
          if (!value && value !== 0) {
            return Promise.resolve();
          }
          const fullTimeEmp = Number(getFieldValue(['numberOfFullTimeEmployees']) || 0);
          const totalEmployeePayroll = Number(getFieldValue(['totalEmployeePayroll']) || 0);
          if (fullTimeEmp === 0 && Number(value) === 0 && totalEmployeePayroll > 0) {
            return Promise.reject('Sum of Part-Time and Full-Time Employees should be greater than 0.');
          }
          return Promise.resolve();
        },
      }),
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.MONEY,
    name: 'Total Payroll',
    dataIndex: 'totalEmployeePayroll',
    fieldMapping: ApplicationFieldPathEnum.TOTAL_EMPLOYEE_PAYROLL,
    placeholder: 'Total Payroll',
    rules: [
      {
        required: true,
        message: 'Please enter Total Payroll.',
      },
      ({ getFieldValue }: { getFieldValue: Function }) => ({
        validator(_: any, value: any) {
          const totalEmployeePayroll = Number(value) || 0;
          const partTimeEmp = Number(getFieldValue(['numberOfPartTimeEmployees']) || 0);
          const fullTimeEmp = Number(getFieldValue(['numberOfFullTimeEmployees']) || 0);

          if (value?.toString() && partTimeEmp + fullTimeEmp > 0 && totalEmployeePayroll <= 0) {
            return Promise.reject('Total Payroll must be greater than zero.');
          }
          return Promise.resolve();
        },
      }),
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.DROPDOWN,
    name: 'Nature of Construction Project',
    dataIndex: 'natureOfConstructionProject',
    fieldMapping: ApplicationFieldPathEnum.NATURE_OF_CONSTRUCTION_PROJECT,
    placeholder: 'Start typing to search Nature of Construction Project',
    options: [
      { label: 'New Construction', value: NatureOfConstructionProject.NEW_CONSTRUCTION },
      { label: 'Renovation', value: NatureOfConstructionProject.RENOVATION },
    ],
    rules: [{ required: true, message: 'Please select Nature of Construction Project.' }],
  },
];
