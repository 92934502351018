import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { useApplicationStore } from '../../pages/v2/application/store';
import { cloneDeep } from '../../utils/lodash';
import { ADDITIONAL_INTEREST_STORE_META_DATA } from './constants';
import { IAdditionalInterestStore } from './interface';
import { createAdditionalInterestApiPayload, validateAdditionalInterestDetails } from './utils';

export const useAdditionalInterestStore = create<IAdditionalInterestStore>()(
  devtools(
    (set, get) => ({
      additionalInterestLoading: false,
      additionalInterestList: [],
      additionalInterestError: undefined,

      autofillAdditionalInterest: () => {
        const { applicationData } = useApplicationStore.getState();
        const { additionalInterestDetails = [] } = applicationData || {};

        set(
          { additionalInterestList: cloneDeep(additionalInterestDetails) },
          false,
          ADDITIONAL_INTEREST_STORE_META_DATA.AUTOFILL_ADDITIONAL_INTEREST,
        );
      },

      validateAdditionalInterest: () => {
        const { additionalInterestList } = get();
        const validatedAdditionalInterestDetails = validateAdditionalInterestDetails(additionalInterestList || []);
        if (!validatedAdditionalInterestDetails.isValid) {
          set(
            { additionalInterestError: validatedAdditionalInterestDetails.errors },
            false,
            ADDITIONAL_INTEREST_STORE_META_DATA.ADDITIONAL_INTEREST_ERROR,
          );
        } else {
          set(
            { additionalInterestError: undefined },
            false,
            ADDITIONAL_INTEREST_STORE_META_DATA.ADDITIONAL_INTEREST_ERROR,
          );
        }
        return validatedAdditionalInterestDetails.isValid;
      },

      getAdditionalInterestApplicationPayload: () => {
        const { additionalInterestList } = get();
        const { applicationData } = useApplicationStore.getState();
        const additionalInterestPayload = createAdditionalInterestApiPayload(additionalInterestList);

        return { ...applicationData!, additionalInterestDetails: additionalInterestPayload };
      },

      updateAdditionalInterestStoreByKey: async (storeKey: keyof IAdditionalInterestStore, value: any) => {
        set(
          () => ({ [storeKey]: value }),
          false,
          ADDITIONAL_INTEREST_STORE_META_DATA.UPDATE_ADDITIONAL_INTEREST_STORE_BY_KEY,
        );
      },

      clearAdditionalInterest: () => {
        set(
          { additionalInterestLoading: false, additionalInterestList: [], additionalInterestError: undefined },
          false,
          ADDITIONAL_INTEREST_STORE_META_DATA.CLEAR_ADDITIONAL_INTEREST_STORE,
        );
      },
    }),

    {
      anonymousActionType: ADDITIONAL_INTEREST_STORE_META_DATA.ANONYMOUS_ACTION_NAME,
      name: ADDITIONAL_INTEREST_STORE_META_DATA.STORE_NAME,
    },
  ),
);
