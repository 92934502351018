import { ICarrierInfo, IPolicyTypeInfo } from '@coverforce-platform/cf-common-api-model';

import { FEATURE_FLAG } from '../../../globalConstants';
import { getGrowthbookFeatureValueByKey } from '../../../utils/growthbook';
import { uniqBy } from '../../../utils/lodash';

export const getAllPoliciesForAgency = (availableCarriersForAgency: ICarrierInfo[] = []): IPolicyTypeInfo[] => {
  const carrierPolicies = availableCarriersForAgency.flatMap((item: ICarrierInfo) => item.availablePolicyTypes || []);
  return uniqBy(carrierPolicies, (item) => item.policyTypeId);
};

export const getAllowedPoliciesForAgency = (allPoliciesForAgency: IPolicyTypeInfo[]): IPolicyTypeInfo[] => {
  const allowedPoliciesAgency: IPolicyTypeInfo[] = [];
  const allowedPolicyTypesGrowthbook = Object.values(
    getGrowthbookFeatureValueByKey(FEATURE_FLAG.ALLOWED_POLICIES) || {},
  );
  allPoliciesForAgency?.forEach((item: IPolicyTypeInfo) => {
    if ((allowedPolicyTypesGrowthbook || []).includes(item.policyTypeId)) {
      allowedPoliciesAgency.push(item);
    }
  });
  return allowedPoliciesAgency;
};

export const getSortedRankedCarriers = (
  rankedCarriers: { [k: string]: string[] },
  policyTypes: { [k: string]: string },
) => {
  return Object.keys(rankedCarriers).sort((policyType1, policyType2) =>
    policyTypes[policyType1].toLowerCase() < policyTypes[policyType2].toLowerCase() ? -1 : 1,
  );
};
